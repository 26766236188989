import React, { Component } from 'react';
import fetchIntercept from 'fetch-intercept';

interface InterceptorProps {
  baseUrl?: string;
}

interface UnauthorizedProps {
  action: () => void;
  check?: boolean;
}

interface IAction {
  cb: any;
  args: any;
}

interface State {
  plugins: any;
}

class Unauthorized extends Component<UnauthorizedProps> {
  render() {
    return null;
  }
}

export default class DinoInterceptor extends Component<InterceptorProps, State> {
  static Unauthorized = Unauthorized;

  constructor(props) {
    super(props);
    this.state = {
      plugins: {
        [DinoInterceptor.Unauthorized.name]: {
          type: 'response',
          action: (response, props) => {
            if (response.status === 401 && (props.check === undefined || props.check)) {
              props.action();
            }
            return response;
          },
        },
      },
    };
  }

  acceptPlugin(plugin) {
    return plugin.type.name in this.state.plugins;
  }

  componentDidMount() {
    let actionLists: { [id: string]: IAction[] } = {
      response: [],
      request: [],
    };

    React.Children.forEach(this.props.children, (child) => {
      if (child && typeof child === 'object' && 'type' in child && this.acceptPlugin(child)) {
        const meta = this.state.plugins[this.getChildName(child)];
        actionLists[meta.type].push({
          cb: meta.action,
          args: child.props,
        });
      }
    });

    fetchIntercept.register({
      request: function (url, config) {
        return [url, config];
      },

      response: (response) => {
        if (this.props.baseUrl && response.url.startsWith(this.props.baseUrl)) {
          actionLists.response.forEach((action) => {
            action.cb(response, action.args);
          });
        }
        return response;
      },
    });
  }

  getChildName(child) {
    return child.type.name;
  }

  render() {
    return null;
  }
}
