import { PerformanceState } from './performance.type';

export const PERFORMANCE_LIST_GET = 'PERFORMANCE_LIST_GET';
export const PERFORMANCE_REQUEST = 'PERFORMANCE_REQUEST';
export const PERFORMANCE_RESPONSE = 'PERFORMANCE_RESPONSE';
export const PERFORMANCE_VALIDATE = 'PERFORMANCE_VALIDATE';
export const PERFORMANCE_EXPORT_PDF = 'PERFORMANCE_EXPORT_PDF';
export const PERFORMANCE_EXPORT_EXCEL = 'PERFORMANCE_EXPORT_EXCEL';

export interface PerfomanceExportPdf {
  type: typeof PERFORMANCE_EXPORT_PDF;
  payload: PerformanceState;
}

export interface PerfomanceExportExcel {
  type: typeof PERFORMANCE_EXPORT_EXCEL;
  payload: PerformanceState;
}

export interface PerformanceValidateAction {
  type: typeof PERFORMANCE_VALIDATE;
  payload: PerformanceState;
}

export interface PerformanceListGetAction {
  type: typeof PERFORMANCE_LIST_GET;
  payload: PerformanceState;
}

export interface PerformanceRequestAction {
  type: typeof PERFORMANCE_REQUEST;
  payload: PerformanceState;
}

export interface PerformanceResponseAction {
  type: typeof PERFORMANCE_RESPONSE;
  payload: PerformanceState;
}

export type PerformanceActions =
  | PerformanceListGetAction
  | PerformanceRequestAction
  | PerformanceResponseAction
  | PerformanceValidateAction
  | PerfomanceExportExcel
  | PerfomanceExportPdf;
