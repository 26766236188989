import { AreaObjectiveState } from './area-objective.type';

export const AREA_OBJECTIVE_LIST = 'AREA_OBJECTIVE_LIST';
export const AREA_OBJECTIVE_REQUEST = 'AREA_OBJECTIVE_REQUEST';
export const AREA_OBJECTIVE_CREATED_RESPONSE = 'AREA_OBJECTIVE_CREATED_RESPONSE';
export const AREA_OBJECTIVE_ID_GET = 'AREA_OBJECTIVE_ID_GET';
export const AREA_OBJECTIVE_UPDATED_RESPONSE = 'AREA_OBJECTIVE_UPDATED_RESPONSE';

export interface AreaObjectiveListAction {
  type: typeof AREA_OBJECTIVE_LIST;
  payload: AreaObjectiveState;
}

export interface AreaObjectiveRequestAction {
  type: typeof AREA_OBJECTIVE_REQUEST;
  payload: AreaObjectiveState;
}

export interface AreaObjectiveCreatedResponseAction {
  type: typeof AREA_OBJECTIVE_CREATED_RESPONSE;
  payload: AreaObjectiveState;
}

export interface AreaObjectiveIdGetAction {
  type: typeof AREA_OBJECTIVE_ID_GET;
  payload: AreaObjectiveState;
}

export interface AreaObjectiveUpdateResponseAction {
  type: typeof AREA_OBJECTIVE_UPDATED_RESPONSE;
  payload: AreaObjectiveState;
}

export type AreaObjectiveActionTypes =
  | AreaObjectiveListAction
  | AreaObjectiveRequestAction
  | AreaObjectiveCreatedResponseAction
  | AreaObjectiveIdGetAction
  | AreaObjectiveUpdateResponseAction;

export type AreaObjectiveActions = AreaObjectiveActionTypes;
