import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import authService from './auth.service';

const OPERATIONS = {
  contains: CondOperator.CONTAINS_LOW,
  equal: CondOperator.EQUALS,
  greaterThan: CondOperator.GREATER_THAN,
  greaterThanOrEqual: CondOperator.GREATER_THAN_EQUALS,
  lessThan: CondOperator.LOWER_THAN,
  lessThanOrEqual: CondOperator.LOWER_THAN_EQUALS,
};

class CompanyObjectiveService {
  private encodeFilterValue(columnName: any, filterValue: any) {
    const columnNames = ['name', 'objective'];
    if (columnNames.includes(columnName)) {
      const requireEncoding = ['\\', ',', '"', '%'];
      for (const c of requireEncoding) {
        if (filterValue.includes(c)) {
          filterValue = (filterValue as String).replace(c, `\\${c}`);
          return filterValue;
        }
      }
    }
    return encodeURI(filterValue);
  }

  private createQueryFilter(
    page: number = 10,
    count: number = 10,
    filters: any[] = [],
    sorting: any[] = []
  ) {
    const qb = RequestQueryBuilder.create().setPage(page).setLimit(count);
    if (filters.length) {
      filters.forEach((filterData) => {
        const value = filterData.value;
        const operator = OPERATIONS[filterData.operation || 'equal'];
        let skip = false;
        let filterName = filterData.columnName;
        switch (filterName) {
          case 'activeStr':
            if (value === -1) {
              skip = true;
              break;
            }
            filterName = 'active';
            break;
          case 'period.period':
            if (value === -1) {
              skip = true;
              break;
            }
            filterName = 'period.period';
            break;
        }
        if (!skip) {
          qb.setFilter({
            field: filterName,
            operator: operator,
            value: this.encodeFilterValue(filterData.columnName, value),
          });
        }
      });
    }
    if (sorting.length > 0) {
      const sortBy: any[] = [];
      for (const sort of sorting) {
        let { columnName, direction } = sort;
        switch (columnName) {
          case 'activeStr':
            columnName = 'active';
            direction = direction.toUpperCase() === 'ASC' ? 'DESC' : 'ASC';
            break;
        }
        sortBy.push({ field: columnName, order: direction.toUpperCase() });
      }
      qb.sortBy(sortBy);
    }
    return qb.query();
  }

  async getCompanyObjectives(
    page: number = 1,
    count: number = 10,
    filters: any[] = [],
    sorting: any[] = []
  ) {
    const query = this.createQueryFilter(page, count, filters, sorting);
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/company-objective?${query}`, {
      headers: authService.authHeader(),
    });
    return response.json();
  }

  async saveCompanyObjective(
    periodId: number,
    objective: string,
    active: boolean,
    name: string | null
  ): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/company-objective`, {
      method: 'POST',
      headers: authService.authHeader(),
      body: JSON.stringify({
        periodId: periodId,
        name: name,
        objective: objective,
        active: active,
      }),
    });
    return response.json();
  }

  async updateCompanyObjective(
    id: string,
    periodId: number,
    objective: string,
    active: boolean,
    name: string | null
  ): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/company-objective/${id}`, {
      method: 'PUT',
      headers: authService.authHeader(),
      body: JSON.stringify({
        id: id,
        periodId: periodId,
        objective: objective,
        active: active,
        name: name,
      }),
    });
    return response.json();
  }

  async getCompanyObjectiveById(id: string) {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/company-objective/${id}`, {
      method: 'GET',
      headers: authService.authHeader(),
    });
    return response.json();
  }
}

const companyObjectiveService = new CompanyObjectiveService();
export default companyObjectiveService;
