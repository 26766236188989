import { CompetenceState } from './competence.type';
import { CompetenceActions, COMPETENCE_LIST, COMPETENCE_REQUEST } from './competence.action.type';

const defaultState: CompetenceState = {
  competence: null,
  competences: [],
  isFetching: false,
  status: null,
  message: null,
};

export default (state = defaultState, action: CompetenceActions): CompetenceState => {
  switch (action.type) {
    case COMPETENCE_LIST: {
      return {
        ...action.payload,
      };
    }
    case COMPETENCE_REQUEST: {
      return {
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
