import { CostCenterState } from './cost-center.type';

export const COST_CENTER_LIST = 'COST_CENTER_LIST';
export const COST_CENTER_REQUEST = 'COST_CENTER_REQUEST';
export const COST_CENTER_ID_GET = 'COST_CENTER_ID_GET';

export interface CostCenterListAction {
  type: typeof COST_CENTER_LIST;
  payload: CostCenterState;
}

export interface CostCenterRequestAction {
  type: typeof COST_CENTER_REQUEST;
  payload: CostCenterState;
}

export interface CostCenterIdGetAction {
  type: typeof COST_CENTER_ID_GET;
  payload: CostCenterState;
}

export type CostCenterActionTypes =
  | CostCenterListAction
  | CostCenterRequestAction
  | CostCenterIdGetAction;

export type CostCenterActions = CostCenterActionTypes;
