import { PeriodState } from './period.type';

export const PERIOD_LIST_GET = 'PERIOD_LIST_GET';
export const PERIOD_ID_GET = 'PERIOD_ID_GET';
export const PERIOD_REQUEST = 'PERIOD_CREATE';
export const PERIOD_RESPONSE = 'PERIOD_CREATE';
export const PERIOD_FEEDBACK_CREATE = 'PERIOD_FEEDBACK_CREATE';
export const PERIOD_FEEDBACK_EDIT = 'PERIOD_FEEDBACK_EDIT';

export interface PeriodFeedbackEditAction {
  type: typeof PERIOD_FEEDBACK_EDIT;
  payload: PeriodState;
}
export interface PeriodFeedbackCreateAction {
  type: typeof PERIOD_FEEDBACK_CREATE;
  payload: PeriodState;
}
export interface PeriodListGetAction {
  type: typeof PERIOD_LIST_GET;
  payload: PeriodState;
}

export interface PeriodIdGetAction {
  type: typeof PERIOD_ID_GET;
  payload: PeriodState;
}

export interface PeriodRequestAction {
  type: typeof PERIOD_REQUEST;
  payload: PeriodState;
}

export interface PeriodResponseAction {
  type: typeof PERIOD_RESPONSE;
  payload: PeriodState;
}

export type PeriodActionTypes =
  | PeriodListGetAction
  | PeriodIdGetAction
  | PeriodRequestAction
  | PeriodResponseAction
  | PeriodFeedbackCreateAction
  | PeriodFeedbackEditAction;

export type PeriodActions = PeriodActionTypes;
