import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import './theme/variables.css';

import React from 'react';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import fetchIntercept from 'fetch-intercept';
import { Provider } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';

import store from './store';
import AdminLayout from './components/layout/AdminLayoutComponent';
import Router from './components/Router';
import errorService from './services/error.service';
import Interceptors from './components/Interceptors';

import authService from './services/auth.service';
import { GalaxyToastContainer } from '@dinocloud/galaxy-components-lib';
import { getUserFromStorage } from './store/user/user.actions';

if (authService.isAuthenticated()) {
  store.dispatch(getUserFromStorage());
}

fetchIntercept.register({
  request: function (url, config) {
    return [url, config];
  },

  response: (response) => {
    if (process.env.REACT_APP_API_HOST && response.url.startsWith(process.env.REACT_APP_API_HOST)) {
      errorService.verifyResponse(response);
    }
    return response;
  },
});

const App: React.FunctionComponent = () => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
    <Provider store={store}>
      <IonApp>
        <IonReactRouter>
          <Interceptors />
          <GalaxyToastContainer />
          <AdminLayout>
            <Router />
          </AdminLayout>
        </IonReactRouter>
      </IonApp>
    </Provider>
  </MuiPickersUtilsProvider>
);

export default App;
