import {
  AreaObjectiveActions,
  AREA_OBJECTIVE_CREATED_RESPONSE,
  AREA_OBJECTIVE_ID_GET,
  AREA_OBJECTIVE_LIST,
  AREA_OBJECTIVE_REQUEST,
  AREA_OBJECTIVE_UPDATED_RESPONSE,
} from './area-objective.action.type';
import { AreaObjectiveState } from './area-objective.type';

const defaultState: AreaObjectiveState = {
  areaObjective: null,
  areaObjectives: [],
  isFetching: false,
  page: 1,
  pageCount: 1,
  total: 1,
  count: 10,
  limit: 10,
  isActionCompleted: false,
  filters: [],
  sorting: [],
  shouldGoToObjetives: false,
  status: null,
};

export default (state = defaultState, action: AreaObjectiveActions): AreaObjectiveState => {
  switch (action.type) {
    case AREA_OBJECTIVE_LIST: {
      return {
        ...action.payload,
      };
    }
    case AREA_OBJECTIVE_REQUEST: {
      return {
        ...action.payload,
      };
    }
    case AREA_OBJECTIVE_CREATED_RESPONSE: {
      return {
        ...action.payload,
      };
    }
    case AREA_OBJECTIVE_ID_GET: {
      return {
        ...action.payload,
      };
    }
    case AREA_OBJECTIVE_UPDATED_RESPONSE: {
      return {
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
