import { CompetenceState } from './competence.type';

export const COMPETENCE_LIST = 'COMPETENCE_LIST';
export const COMPETENCE_REQUEST = 'COMPETENCE_REQUEST';

export interface CompentenceListAction {
  type: typeof COMPETENCE_LIST;
  payload: CompetenceState;
}

export interface CompentenceRequestAction {
  type: typeof COMPETENCE_REQUEST;
  payload: CompetenceState;
}

export type CompetenceActionTypes = CompentenceListAction | CompentenceRequestAction;

export type CompetenceActions = CompetenceActionTypes;
