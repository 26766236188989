import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IonLoading } from '@ionic/react';
import { withRouter, RouteComponentProps } from 'react-router';

import { RootState } from '../store/root-reducer';
import { routeTo } from '../store/router/router.actions';
import authService from '../services/auth.service';
import roles from '../constants/roles';
import userService from '../services/user.service';

const MainPage = React.lazy(() => import('../pages/main/MainPage'));
const CollabolatorPeriodPage = React.lazy(
  () => import('../pages/collaborator-period/CollabolatorPeriodPage')
);
const PerformanceContainerPage = React.lazy(
  () => import('../pages/performance/PerformanceContainerPage')
);
const CompetenceContainerPage = React.lazy(
  () => import('../pages/competence/CompetenceContainerPage')
);
const PeriodContainerPage = React.lazy(() => import('../pages/period/PeriodContainerPage'));
const PeriodListPage = React.lazy(() => import('../pages/period-list/PeriodListPage'));
const PerformanceDetailPage = React.lazy(
  () => import('../pages/performance-detail/PerformanceDetailPage')
);
const PerformancePendingPage = React.lazy(
  () => import('../pages/performance-pending/PerformancePendingPage')
);
const EditObjectivePage = React.lazy(
  () => import('../pages/performance/EditObjectivePage')
);
const CompanyObjectiveMainPage = React.lazy(
  () => import('../pages/company-objective/CompanyObjectiveMainPage')
);
const CompanyObjectivePage = React.lazy(
  () => import('../pages/company-objective/CompanyObjectivePage')
);
const PeriodFeedbackMainPage = React.lazy(
  () => import('../pages/period-feedback/PeriodFeedbackMainPage')
);
const FeedbackMainListPage = React.lazy(() => import('../pages/feedback/FeedbackMainListPage'));
const FeedbackPage = React.lazy(() => import('../pages/feedback/FeedbackPage'));
const AreaObjectiveMainPage = React.lazy(
  () => import('../pages/area-objective/AreaObjectiveMainPage')
);
const AreaObjectivePage = React.lazy(() => import('../pages/area-objective/AreaObjectivePage'));
const PerformanceReportMainPage = React.lazy(
  () => import('../pages/performance-report/PerformanceReportMainPage')
);
const FeedbackLeaderMainListPage = React.lazy(
  () => import('../pages/feeback-leader/FeedbackLeaderMainListPage')
);
const FeedbackLeaderPage = React.lazy(() => import('../pages/feeback-leader/FeedbackLeaderPage'));
const SelfAppraisalPage = React.lazy(() => import('../pages/self-appraisal/SelfAppraisalPage'));
const LeaderAppraisalContainerPage = React.lazy(
  () => import('../pages/self-appraisal/LeaderAppraisal/LeaderAppraisalContainerPage')
);

interface RouterPageState {
  userRole: string;
}

type Props = LinkDispatchProps & LinkStateProps & RouteComponentProps<any>;

interface RoutePage {
  path: string;
  component: any;
}

const routes: RoutePage[] = [
  {
    path: '/periods',
    component: PeriodListPage,
  },
  {
    path: '/competences',
    component: CompetenceContainerPage,
  },
  {
    path: '/performances',
    component: PerformanceContainerPage,
  },
  {
    path: '/edit-performance',
    component: EditObjectivePage,
  },
  {
    path: '/main',
    component: MainPage,
  },
  {
    path: '/perf-detail',
    component: PerformanceDetailPage,
  },
  {
    path: '/perf-pending',
    component: PerformancePendingPage,
  },
  {
    path: '/colla-period',
    component: CollabolatorPeriodPage,
  },
  {
    path: '/period',
    component: PeriodContainerPage,
  },
  {
    path: '/company-objectives',
    component: CompanyObjectiveMainPage,
  },
  {
    path: '/company-objective',
    component: CompanyObjectivePage,
  },
  {
    path: '/period-feedback',
    component: PeriodFeedbackMainPage,
  },
  {
    path: '/period-feedback/edit/:id',
    component: PeriodFeedbackMainPage,
  },
  {
    path: '/feedbacks',
    component: FeedbackMainListPage,
  },
  {
    path: '/new-feedbacks/:perfid',
    component: FeedbackPage,
  },
  {
    path: '/edit-feedbacks/:feedbackid',
    component: FeedbackPage,
  },
  {
    path: '/performance-report',
    component: PerformanceReportMainPage,
  },
  {
    path: '/area-objectives',
    component: AreaObjectiveMainPage,
  },
  {
    path: '/area-objective',
    component: AreaObjectivePage,
  },
  {
    path: '/feedbacks-leaders/:perfid',
    component: FeedbackLeaderMainListPage,
  },
  {
    path: '/new-feedback-leader/:feedbackid',
    component: FeedbackLeaderPage,
  },
  {
    path: '/self-appraisal',
    component: SelfAppraisalPage,
  },
  {
    path: '/appraisal',
    component: LeaderAppraisalContainerPage,
  }
];

class Router extends React.Component<Props, RouterPageState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userRole: '',
    };
  }

  private createLoginCallbackUrl(propsRouter: any) {
    const prefix = propsRouter.history.location.search
      ? propsRouter.history.location.search.concat('&')
      : '?';
    return `${
      process.env.REACT_APP_URBANO_LOGIN_HOST
    }${prefix}callback=${encodeURIComponent(window.location.href)}`;
  }

  private renderLoading() {
    return <IonLoading isOpen={true} />;
  }

  async componentDidUpdate(prevProps, prevState) {
    if (!this.state.userRole) {
      if (this.props.userRole !== prevProps.userRole) {
        if (this.props.userRole) {
          this.setState({ userRole: this.props.userRole });
        } else {
          this.setState({ userRole: await userService.forceGetRole() });
        }
      }
    }
  }

  render() {
    return (
      <>
        <Suspense fallback={<div>{this.renderLoading()}</div>}>
          { routes.map((route, i) => (
              <Route
                key={i}
                path={route.path}
                exact={true}
                render={(props) => {
                  this.props.routeTo(route.path);
                  return !authService.isAuthenticated() ? (
                    (window.location.href = `${this.createLoginCallbackUrl(props)}`)
                  ) : (
                    <route.component {...props} />
                  );
                }}
              />
          ))}
          <Route exact={true} path="/" render={() => <Redirect to="/main" />} />
        </Suspense>
      </>
    );
  }
}

interface LinkStateProps {
  userRole: string;
}

interface LinkDispatchProps {
  routeTo: (url: string) => void;
}

const mapState = (state: RootState): LinkStateProps => {
  const { currentUser } = state.user;

  return {
    userRole: (currentUser && currentUser.role && currentUser.role.alias) || '',
  };
};

const mapDispatchToProps = (dispatch: any): LinkDispatchProps => ({
  routeTo: bindActionCreators(routeTo, dispatch),
});

export default withRouter(connect(mapState, mapDispatchToProps)(Router));

