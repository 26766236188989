import JwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { User } from '../store/user/user.types';

class AuthService {
  authHeader = (refresh = true): HeadersInit => {
    if (refresh) this.tryToRefreshToken();
    return this.getDefaultHeaders();
  };

  getToken = (): string | null => {
    return Cookies.get('token', {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
    });
  };

  getTokenDecode() {
    const token = this.getToken() || '';
    return JwtDecode(token);
  }

  isAuthenticated = (): boolean => {
    return (
      !!Cookies.get('token', {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
      }) &&
      !this.isTokenExpired(
        Cookies.get('token', { domain: process.env.REACT_APP_COOKIES_DOMAIN })
      )
    );
  };

  cleanStorage = (): void => {
    Cookies.remove('token', { domain: process.env.REACT_APP_COOKIES_DOMAIN });
    Cookies.remove('refreshToken', {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
    });
    Cookies.remove('user', { domain: process.env.REACT_APP_COOKIES_DOMAIN });
    localStorage.clear();
  };

  private getRefreshToken = (): string | null => {
    return Cookies.get('refreshToken', {
      domain: process.env.REACT_APP_COOKIES_DOMAIN,
    });
  };

  private tryToRefreshToken = async () => {
    try {
      const refreshToken: any = this.getRefreshToken();
      const token: any = this.getToken();
      if (!!token) {
        const decoded: any = JwtDecode(token);
        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        const diff = (Date.now() - date.getTime()) / 1000 / 60;
        const minute = Math.abs(Math.round(diff));
        if (minute < 10) {
          const resp: any = await fetch(
            `${process.env.REACT_APP_URBANO_USER_API}/users`,
            {
              method: 'POST',
              headers: this.getDefaultHeaders(),
              body: JSON.stringify({
                query: `mutation{refreshToken(refreshToken:"${refreshToken}")}`,
              }),
            }
          );
          const respJson = await resp.json();
          if (!!respJson && !!respJson.data) {
            Cookies.set('token', respJson.data.refreshToken, {
              domain: process.env.REACT_APP_COOKIES_DOMAIN,
            });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  private isTokenExpired = (token: any) => {
    try {
      const decoded: any = JwtDecode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Token is expired
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log('expired check failed!: AuthService');
      return false;
    }
  };

  private getDefaultHeaders = () => {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getToken()}`,
    };
  };

  getCurrentUser() {
    const userData: User = JSON.parse(localStorage.getItem('user')!);
    return userData;
  }
}

const authService = new AuthService();
export default authService;
