import { SelfAppraisalState } from './self-appraisal.type';

export const SELF_APPRAISAL_LIST_GET = 'SELF_APPRAISAL_LIST_GET';
export const APPRAISAL_STATUS_BY_LEADER_GET = 'APPRAISAL_STATUS_BY_LEADER_GET';
export const CREATE_SELF_APPRAISAL = 'CREATE_BULK_SELF_APPRAISAL';
export const UPDATE_SELF_APPRAISAL = 'UPDATE_SELF_APPRAISAL';
export const CONFIRM_SELF_APPRAISAL = 'CONFIRM_SELF_APPRAISAL';
export const EXPORT_APPRAISAL_EXCEL = 'EXPORT_APPRAISAL_EXCEL';
export const EXPORT_SELF_APPRAISAL_PDF = 'EXPORT_SELF_APPRAISAL_PDF';

export interface SelfAppraisalListGetAction {
  type: typeof SELF_APPRAISAL_LIST_GET;
  payload: SelfAppraisalState;
}

export interface SelfAppraisalStatusByLeaderGetAction {
  type: typeof APPRAISAL_STATUS_BY_LEADER_GET;
  payload: SelfAppraisalState;
}

export interface CreateSelfAppraisalAction {
  type: typeof CREATE_SELF_APPRAISAL;
  payload: SelfAppraisalState;
}

export interface UpdateSelfAppraisalAction {
  type: typeof UPDATE_SELF_APPRAISAL;
  payload: SelfAppraisalState;
}

export interface ConfirmSelfAppraisalAction {
  type: typeof CONFIRM_SELF_APPRAISAL;
  payload: SelfAppraisalState;
}
export interface ExportAppraisalExcelAction {
  type: typeof EXPORT_APPRAISAL_EXCEL;
  payload: SelfAppraisalState;
}


export interface ExportSelfAppraisalPDFAction {
  type: typeof EXPORT_SELF_APPRAISAL_PDF;
  payload: SelfAppraisalState;
}

export type SelfAppraisalActionTypes =
  | SelfAppraisalListGetAction
  | CreateSelfAppraisalAction
  | UpdateSelfAppraisalAction
  | ConfirmSelfAppraisalAction
  | SelfAppraisalStatusByLeaderGetAction
  | ExportAppraisalExcelAction
  | ExportSelfAppraisalPDFAction;

export type SelfAppraisalActions = SelfAppraisalActionTypes;
