import { CompanyObjectiveState } from './company-objective.type';

export const COMPANY_OBJECTIVE_LIST = 'COMPANY_OBJECTIVE_LIST';
export const COMPANY_OBJECTIVE_REQUEST = 'COMPANY_OBJECTIVE_REQUEST';
export const COMPANY_OBJECTIVE_CREATED_RESPONSE = 'COMPANY_OBJECTIVE_CREATED_RESPONSE';
export const COMPANY_OBJECTIVE_ID_GET = 'COMPANY_OBJECTIVE_ID_GET';
export const COMPANY_OBJECTIVE_UPDATED_RESPONSE = 'COMPANY_OBJECTIVE_UPDATED_RESPONSE';

export interface CompanyObjectiveListAction {
  type: typeof COMPANY_OBJECTIVE_LIST;
  payload: CompanyObjectiveState;
}

export interface CompanyObjectiveRequestAction {
  type: typeof COMPANY_OBJECTIVE_REQUEST;
  payload: CompanyObjectiveState;
}

export interface CompanyObjectiveCreatedResponeAction {
  type: typeof COMPANY_OBJECTIVE_CREATED_RESPONSE;
  payload: CompanyObjectiveState;
}

export interface CompanyObjectiveIdGetAction {
  type: typeof COMPANY_OBJECTIVE_ID_GET;
  payload: CompanyObjectiveState;
}

export interface CompanyObjectiveUpdateResponseAction {
  type: typeof COMPANY_OBJECTIVE_UPDATED_RESPONSE;
  payload: CompanyObjectiveState;
}

export type CompanyObjectiveActionTypes =
  | CompanyObjectiveListAction
  | CompanyObjectiveRequestAction
  | CompanyObjectiveCreatedResponeAction
  | CompanyObjectiveIdGetAction
  | CompanyObjectiveUpdateResponseAction;

export type CompanyObjectiveActions = CompanyObjectiveActionTypes;
