import React, { Component } from 'react';
import {
  IonIcon,
  IonPage,
  IonSplitPane,
} from '@ionic/react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { getPeriodById } from '../../store/period/period.action';
import SideMenu from './SideMenuComponent';
import { RootState } from '../../store/root-reducer';
import { User } from '../../store/user/user.types';
import './AdminLayoutComponent.css';
import { logoutUser } from '../../store/user/user.actions';
import {
  getNotifications,
  deleteNotification,
  deleteNotificationToken,
} from '../../store/notification/notification.action';
import { Notification } from '../../store/notification/notification.type';
import { getPendingRevision } from '../../store/performance/performance.actions';
import { Performance } from '../../store/performance/performance.type';
import { GalaxyNotification, GalaxyToast, GalaxyTopBar, GalaxyTopBarAvatar, GalaxyTopBarButton } from '@dinocloud/galaxy-components-lib';
import { exit } from 'ionicons/icons';
import { formatDate } from '../../services/utils.service';

type Props = RouterProps & LinkDispatchProps & RouteComponentProps<any>;

interface RouterProps {
  title: string;
  showHeader: boolean;
  user?: User | null;
  notifications?: Notification[];
  performanceList: Performance[];
  currentUser?: User | null;
}

interface State {
  showUserMenu: boolean;
  anchorEl: HTMLElement | null;
  intervalId: any;
}

export class AdminLayout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showUserMenu: false,
      anchorEl: null,
      intervalId: null,
    };
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  componentDidUpdate() {
    this.loadNotifications();
  }

  private async validateToastForApprove() {
    if (!!this.props.user && !!this.props.user.id) {
      if (
        !!this.props.performanceList &&
        this.props.performanceList.length === 0 &&
        !!this.props.notifications &&
        this.props.notifications.length > 0
      ) {
        const n = this.props.notifications[0];
        if (!!n && !!n.id && !!n.type && n.type.name === 'objectivesForApprove') {
          await this.props.deleteNotification(n.id);
        }
      }
    }
  }

  private async loadNotifications() {
    if (!!this.props.user && !!this.props.user.id) {
      if (!this.state.intervalId) {
        const intervalId = setInterval(() => {
          this.initLoad();
        }, 10000);
        this.setState({ intervalId: intervalId });
        await this.initLoad();
      }
    }
  }

  private async initLoad() {
    if (!!this.props.user && !!this.props.user.id) {
      await this.props.getNotifications();
      await this.props.getPendingRevision();
      this.validateToastForApprove();
    }
  }

  private handleCloseUserMenu() {
    this.setState({ anchorEl: null });
  }

  private async logout(error?: string) {
    this.handleCloseUserMenu();
    await this.props.logoutUser();
    const path = !!error ? `?e=${error}` : '/';
    this.props.history.push(path);
  }

  //TODO cambiar
  private getRoles() {
    return 'General';
  }

  getRedirectNotificationTypes():string[] {
    return [
      'selfAppraisalApprove',
      'objectiveAnnulled' ,
      'objectivesApproved',
      'objectiveRejected'
    ]
  }

  getButtonName(type) {
    if(this.getRedirectNotificationTypes().includes(type)) {
      return 'Ir a mis objetivos'
    } else if (type === 'downloadFile' || type === 'downloadFilePdf'){
      return 'Descargar'
    } else {
      return 'Eliminar'
    }
  }

  private renderToastNotification() {
    const notificationList: any[] = [];
    if (!!this.props.notifications && this.props.notifications.length > 0) {
      this.props.notifications.forEach((n) => {
        if (!!n.id && !!n.createdAt) {
          const dateStr = formatDate(new Date(n.createdAt), true);

          const item: any = {
            title: 'Notificacion de evaluación de desempeño',
            description: n.type? n.type.displayName : 'Tenés una nueva notificacion',
            createdAt: dateStr || '',
            buttonName: n.type && n.type.name ? this.getButtonName(n.type.name) : 'eliminar',
            buttonAction: async () => {
              if (!!n.id) {
                if (n.type && n.type.name && this.getRedirectNotificationTypes().includes(n.type.name)) {
                  let url: string | null = '/';
                  let params = '';
                  if (n.type.name === 'selfAppraisalApprove') {
                    url = 'appraisal';
                    const metadata = !!n.metadata ? JSON.parse(n.metadata) : JSON.parse('{}');
                    params = !!metadata.appraisal
                      ? '?collaboratorId=' +
                        metadata.appraisal.collaboratorId +
                        '&periodId=' +
                        metadata.appraisal.periodId
                      : '';
                  } else if (n.type.name === 'objectiveAnnulled') {
                    url = 'performances';
                  } else if (n.type.name === 'objectivesApproved') {
                    url = 'performances';
                  } else if (n.type.name === 'objectivesRejected') {
                    url = 'performances';
                  } else if (n.type.name === 'objectiveRejected') {
                    url = 'performances';
                  }

                  this.props.history.push({ pathname: url, search: params });
                } else if(n.type && (n.type.name === 'downloadFile' || n.type.name === 'downloadFilePdf')) {
                  let url;
                  if (n.type.name === 'downloadFilePdf') {
                    url = n.location;
                  } else {
                    url = !!n.key ? `${window.location.origin}/${n.key}` : null;
                  }
                  window.open(url, '_blank');
                }
                await this.props.deleteNotification(n.id);
              }
            },
          };
          notificationList.push(item);
        }
      });
    }
    const key = Math.round(Math.random() * 1236);
    return (
      <GalaxyNotification
        key={key}
        shouldDeleteAfterAction={true}
        notifications={notificationList}
      />
    );
  }

  render() {
    return (
      <IonSplitPane contentId="menuContent" class={'AdminLayout'}>
        <SideMenu />
        <IonPage id={'menuContent'}>
          <GalaxyTopBar
            key={11}
            show={this.props.showHeader}
            applicationName="Evaluación de desempeño"
            pageName={this.props.title}
            onClickAppButton={() => {
              window.location.href = `${process.env.REACT_APP_URBANO_APPS_HOST}`;
            }}
          >
            <GalaxyTopBarButton key={12} className="TopBar__Logout__button">
              {this.renderToastNotification()}
            </GalaxyTopBarButton>
            <GalaxyTopBarAvatar
              name={
                (this.props.currentUser && this.props.currentUser.fullName) || 'Usuario'
              }
              role={this.getRoles()}
            />
            <GalaxyTopBarButton
              key={13}
              onClick={() => this.logout()}
              className="TopBar__Logout__button"
            >
              <IonIcon icon={exit} className="TopBar__Logout__icon" />
            </GalaxyTopBarButton>
          </GalaxyTopBar>
          {this.props.children}
        </IonPage>
      </IonSplitPane>
    );
  }
}

interface LinkDispatchProps {
  logoutUser: () => void;
  getPeriodById: (id: string) => void;
  getNotifications: () => void;
  deleteNotification: (id: string) => void;
  deleteNotificationToken: () => void;
  getPendingRevision: () => void;
}

const mapStateToProps = (state: RootState): RouterProps => {
  const { title, showHeader } = state.router;
  const { user, currentUser} = state.user;
  const { notifications } = state.notification;
  const { performanceList } = state.performance;
  return {
    showHeader: showHeader,
    title: title,
    currentUser: currentUser || null,
    user: user,
    notifications: notifications || [],
    performanceList: performanceList || [],
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): LinkDispatchProps => ({
  logoutUser: bindActionCreators(logoutUser, dispatch),
  getPeriodById: bindActionCreators(getPeriodById, dispatch),
  getNotifications: bindActionCreators(getNotifications, dispatch),
  deleteNotification: bindActionCreators(deleteNotification, dispatch),
  deleteNotificationToken: bindActionCreators(deleteNotificationToken, dispatch),
  getPendingRevision: bindActionCreators(getPendingRevision, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminLayout));
