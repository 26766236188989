import { User, UserState } from './user.types';
import { Dispatch } from 'react';
import {
  UserActions,
  GET_ALL_COLLABORATOR,
  IS_LIDER,
  isLiderAction,
  GET_USER_BY_ID,
  USER_REQUEST,
  USER_RESPONSE
} from './user.action.type';
import { RootState } from '..';
import userService from '../../services/user.service';
import authService from '../../services/auth.service';
import costCenterService from '../../services/cost-center.service';
import Cookies from 'js-cookie';
//import notificationService from '../../services/notifications.service';

const userRequest = (userState: UserState): UserActions => {
  return {
    type: USER_REQUEST,
    payload: userState,
  };
};

const userResponse = (userState: UserState): UserActions => {
  return {
    type: USER_RESPONSE,
    payload: userState,
  };
};

export const getUserFromStorage = () => {
  return async (dispatch: Dispatch<UserActions>, getState: () => RootState) => {
    const userCookie: User = JSON.parse(
      Cookies.get('user', { domain: process.env.REACT_APP_COOKIES_DOMAIN })!
    );
    const userData = await userService.getUserData();
    if (!!userData && !!userData.data) {
      const user = userData.data.getUserData;

      const role = user.permissions.performance
        ? user.permissions.performance.length > 1
          ? user.permissions.performance.indexOf('load') !== -1 &&
            user.permissions.performance.indexOf('authorizing')
            ? 'authorizing'
            : user.permissions.performance[0]
          : user.permissions.performance[0] || ''
        : null;
      const rolePermission = await userService.getPermissions();
      const permissions =
        !!rolePermission && !!rolePermission.data && rolePermission.data.length > 0
          ? rolePermission.data
          : [];
      const idx = permissions.findIndex((per: any) => !!per && per.alias === role);
      if (idx === -1) {
        dispatch(
          userResponse({
            ...getState().user,
            authenticated: false,
            user: null,
            isFetching: false,
            shouldCallLogoutUser: true,
          })
        );
      } else {
        const roleData = permissions[idx];
        const userDataDocket = await userService.getUserByDocketId(userData.data.getUserData.externalDocketId+'');
        const isLider =  await userService.isLider(userData.data.getUserData.externalDocketId+'');
        const costCenter =  await costCenterService.getCostCenterByCode(userDataDocket.ccTango);
        const user: User = {
          id: userData.data.getUserData.id,
          fullName: userDataDocket.fullName,
          referenceStatus: userDataDocket.active,
          userCode: userData.data.getUserData.email,
          email: userData.data.getUserData.email,
          name: userDataDocket.fullName,
          children: userDataDocket.amountOfChildren,
          gender: userDataDocket.gender,
          civilStatus: userDataDocket.maritalStatus,
          birthDate: userDataDocket.birthdate,
          CUIL: userDataDocket.cuil,
          DNI: userDataDocket.identityNumber,
          admissionDate: userDataDocket.startDate,
          realAdmissionDate: null, // Sin Uso
          adress: userDataDocket.adress,
          state: userDataDocket.province,
          neighborhood: userDataDocket.neighborhood,
          city: userDataDocket.city,
          postalCode: userDataDocket.zipcode,
          phone: userDataDocket.phoneNumber,
          celphone: userDataDocket.cellPhoneNumber,
          celphoneUEA: userDataDocket.cellphoneNumberUEA,
          egressDate: null, // Sin Uso
          reason: userDataDocket.reason,
          costCenter: costCenter,
          category: userDataDocket.cuil,
          categoryCode: null, // Sin Uso
          categoryDescription: null, // Sin Uso
          bossName: userDataDocket.leaderUrbano, 
          branch: userDataDocket.subsidiary,
          coefficient: null, // Sin Uso
          zone: userDataDocket.zone,
          ccUrbano: null, // Sin Uso
          centralization: userDataDocket.centralization,
          hiring: null, // Sin Uso
          healthInsurance: userDataDocket.healthInsurance,
          prepaidHealthInsurance: userDataDocket.prepaidHealth,
          plan: userDataDocket.prepaidPlanHealth,
          adherents: null, // Sin Uso
          task: userDataDocket.taskId,
          uniform: null, // uniformId no existe tal columna
          liquidaHe: userDataDocket.shouldLiquidationHE,
          driveClark: userDataDocket.shouldManagerClark,
          loadAndUnload: userDataDocket.shouldLoadAndDownload,
          isAdmin: role == 'rrhh' ? true : false, 
          idLegajo: userDataDocket.docketId,
          nroLegajo: userDataDocket.docketNumber,
          bossId: userDataDocket.leaderDocketId,
          ccTango: userDataDocket.ccTango,
          lastLoginDate: userData ? userData.lastLoginDate : null,
          isLeader: isLider.data.isLider, 
          isManager: role == 'manager' ? true : false, 
        };
        localStorage.setItem('user', JSON.stringify(user));    
        dispatch(
          userResponse({
            ...getState().user,
            user: { 
              ...userCookie, 
              id: userData.data.getUserData.id,
              idLegajo: userDataDocket.docketId,
              costCenter: costCenter,
              isAdmin: role == 'rrhh' ? true : false,
              isLeader: isLider.data.isLider, 
              isManager: role == 'manager' ? true : false, 
              fullName: userDataDocket.fullName, 
              nroLegajo: userDataDocket.docketNumber, 
              branch: userDataDocket.subsidiary, 
              bossId: userDataDocket.leaderDocketId, 
              bossName: userDataDocket.leaderUrbano, 
            },
            authenticated: true,
            isFetching: false,
            currentUser: {
              ...user,
              id: userData.data.getUserData.id,
              authenticated: true,
              fullName: user.fullName,
              role: { ...roleData },
            },
            rolePermissions: permissions,
            shouldCallLogoutUser: false,
          })
        );
      }
    } else {
      dispatch(
        userResponse({
          ...getState().user,
          user: null,
          authenticated: false,
          isFetching: false,
          shouldCallLogoutUser: true,
        })
      );
    }
  };
};

const isLiderRequest = (user: UserState): isLiderAction => {
  return {
    type: IS_LIDER,
    payload: user,
  };
};

export const getCurrentUser = () => {
  const user: User = authService.getCurrentUser();
  return {
    type: GET_USER_BY_ID,
    payload: user,
  };
};

export const getCollaborators = (idLeader: string) => {
  return async (dispatch: Dispatch<UserActions>, getState: () => RootState) => {
    const payload: UserState = { ...getState().user };
    if (idLeader !== '') {
      const response: any = await userService.getCollaboratorByLeader(idLeader);
      payload.collaborators = response.data;
    }
    dispatch({
      type: GET_ALL_COLLABORATOR,
      payload: payload,
    });
  };
};

export const isLiderValidation = () => {
  const userData: User = JSON.parse(localStorage.getItem('user')!);
  return async (dispatch: Dispatch<UserActions>, getState: () => RootState) => {
    dispatch(isLiderRequest({ ...getState().user }));
    if (!!userData && !!userData.idLegajo) {
      const response: any = await userService.isLider(userData.idLegajo);
      dispatch(
        isLiderRequest({
          ...getState().user,
          isLider: !!response.data ? response.data.isLider : false,
        })
      );
    } else {
      dispatch(
        isLiderRequest({
          ...getState().user,
        })
      );
    }
  };
};

export const logoutUser = () => {
  return async (dispatch: Dispatch<UserActions>, getState: () => RootState) => {
    authService.cleanStorage();
    return dispatch(
      userResponse({
        ...getState().user,
        authenticated: false,
        shouldCallLogoutUser: true,
      })
    );
  };
};
