import { RouterActions, ROUTE_URL } from './router.actions.type';
import { RouterState } from './router.type';
import { Dispatch } from 'redux';
import { RootState } from '../root-reducer';

const routerState = (routerState: RouterState): RouterActions => {
  return {
    type: ROUTE_URL,
    payload: routerState,
  };
};

export const routeTo = (route: string) => {
  return async (dispatch: Dispatch<RouterActions>, getState: () => RootState) => {
    let state: RouterState;
    switch (route) {
      case '/login':
        state = {
          title: 'login',
          showHeader: false,
        };
        break;
      case '/main':
        state = {
          title: 'Datos del colaborador',
          showHeader: true,
        };
        break;
      case '/competences':
        state = {
          title: 'Competencia',
          showHeader: true,
        };
        break;
      case '/periods':
        state = {
          title: 'Períodos',
          showHeader: true,
        };
        break;
      case '/period':
        state = {
          title: 'Período',
          showHeader: true,
        };
        break;
      case '/company-objective':
        state = {
          title: 'Objetivo',
          showHeader: true,
        };
        break;
      case '/company-objectives':
        state = {
          title: 'Objetivo Empresa',
          showHeader: true,
        };
        break;
      case '/performance-report':
        state = {
          title: 'Reporte de Objetivos',
          showHeader: true,
        };
        break;
      default:
        state = {
          title: 'Urbano',
          showHeader: true,
        };
        break;
    }
    dispatch(routerState(state));
  };
};
