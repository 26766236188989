import ROLES from './roles';
import { TEST } from './resources';
import { CREATE, LIST, UPDATE, DELETE, ASSIGN, AUTHORIZE, EDIT } from './actions';

export default {
  [ROLES.GERENTE]: {
    [LIST]: [],
    [CREATE]: [],
    [UPDATE]: [],
    [DELETE]: [],
    [ASSIGN]: [],
  },
  [ROLES.RRHH]: {
    [LIST]: [],
    [CREATE]: [],
    [UPDATE]: [],
    [EDIT]: [],
    [DELETE]: [],
    [ASSIGN]: [],
  },
  [ROLES.GENERAL]: {
    [LIST]: [],
    [CREATE]: [],
    [UPDATE]: [],
    [DELETE]: [],
    [EDIT]: [],
    [ASSIGN]: [],
    [AUTHORIZE]: [],
  },
};
