import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import DinoInterceptor from './dino-components/DinoInterceptor';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logoutUser } from '../store/user/user.actions';
import { deleteNotificationToken } from '../store/notification/notification.action';
import { GalaxyToast } from '@dinocloud/galaxy-components-lib';

interface LinkDispatchProps {
  logoutUser: () => void;
  deleteNotificationToken: () => void;
}
type Props = RouteComponentProps & LinkDispatchProps;

class Interceptors extends React.Component<Props> {
  render() {
    return (
      <DinoInterceptor baseUrl={process.env.REACT_APP_API_HOST}>
        <DinoInterceptor.Unauthorized
          action={async () => {
            await this.props.logoutUser();
            GalaxyToast({
              header: 'La sesión expiró. Vuelva a iniciar sesión.',
              type: 'info',
            });
            setTimeout(() => {
              this.props.history.push('/');
            }, 1500);
          }}
        />
      </DinoInterceptor>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): LinkDispatchProps => ({
  logoutUser: bindActionCreators(logoutUser, dispatch),
  deleteNotificationToken: bindActionCreators(deleteNotificationToken, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(Interceptors));