import { User, UserState } from './user.types';

// action strings
export const USER_REQUEST = 'USER_REQUEST';
export const USER_RESPONSE = 'USER_RESPONSE';
export const GET_ALL_COLLABORATOR = 'GET_ALL_COLLABORATOR';
export const IS_LIDER = 'IS_LIDER';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';

export interface UserRequest {
  type: typeof USER_REQUEST;
  payload: UserState;
}

export interface UserResponse {
  type: typeof USER_RESPONSE;
  payload: UserState;
}

export interface GetAllCollaboratorAction {
  type: typeof GET_ALL_COLLABORATOR;
  payload: UserState;
}

export interface isLiderAction {
  type: typeof IS_LIDER;
  payload: UserState;
}

export interface GetUserByIdAction {
  type: typeof GET_USER_BY_ID;
  payload: User;
}

export type UserActionTypes =
  | UserRequest
  | UserResponse
  | isLiderAction
  | GetUserByIdAction
  | GetAllCollaboratorAction;

export type UserActions = UserActionTypes;
