import {
  FeedbackStatusActions,
  FEEDBACK_STATUS_LIST,
  FEEDBACK_STATUS_REQUEST,
} from './feedback-status.action.type';
import { FeedbackStatusState } from './feedback-status.type';

const defaultState: FeedbackStatusState = {
  feedbackStatuses: [],
  page: 1,
  pageCount: 1,
  total: 1,
  count: 10,
  limit: 10,
};

export default (state = defaultState, action: FeedbackStatusActions): FeedbackStatusState => {
  switch (action.type) {
    case FEEDBACK_STATUS_LIST: {
      return {
        ...action.payload,
      };
    }
    case FEEDBACK_STATUS_REQUEST: {
      return {
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
