import authService from './auth.service';
import permissions from '../constants/permissions';
import { Role } from '../store/user/user.types';

class UserService {
  async getCollaboratorByLeader(idLeader: string) {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/users/${idLeader}/collaborators`, {
      headers: authService.authHeader(),
    });
    return res.json();
  }

  async isLider(id: string) {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/users/${id}/is-lider`, {
      headers: authService.authHeader(),
    });
    return res.json();
  }

  async getUserByDocketId(id: string)  {
    const response = await fetch(`${process.env.REACT_APP_URBANO_DOCKET_API}/dockets/docketId/${id}`, {
      headers: authService.authHeader(),
      method: 'GET',
    });

    return response.json();
  };

  async getUserbyId(id: string) {
    const res = await fetch(`${process.env.REACT_APP_API_HOST}/users/${id}`, {
      headers: authService.authHeader(),
    });
    return res.json();
  }

  async getUserData(): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_URBANO_USER_API}/users`, {
      headers: {
        ...authService.authHeader(),
        Authorization: `Bearer ${authService.getToken()}`,
      },
      method: 'POST',
      body: JSON.stringify({
        query: `{
                getUserData {
                    id
                    fullName
                    email
                    externalDocketId
                    permissions
                }
            }`,
      }),
    });
    return response.json();
  }

  async getAllUsers(): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_URBANO_USER_API}/users`, {
      headers: authService.authHeader(),
      method: 'POST',
      body: JSON.stringify({
        query: `query{filterByPermissions(permissionKey: "performance", take: 590){id, fullName, permissions}}`,
      }),
    });
    return response.json();
  }

  async getPermissionByDocketNumber(docketNumber: string): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_URBANO_USER_API}/users`, {
      headers: authService.authHeader(),
      method: 'POST',
      body: JSON.stringify({
        query: `query{filterByPermissions(permissionKey: null, nroLegajo: "${docketNumber}", take: 590){id, fullName, permissions}}`,
      }),
    });
    return response.json();
  }

  async getRoleByAlias(alias: string) {
    const params = encodeURI(
      `filter=system.alias||eq||performance&filter=alias||eq||${alias}`
    );
    const response = await fetch(
      `${process.env.REACT_APP_URBANO_USER_API}/permissions?${params}`,
      {
        headers: authService.authHeader(),
      }
    );
    return response.json();
  }

  async getPermissions() {
    const params = encodeURI(`filter=system.alias||eq||performance`);
    const response = await fetch(
      `${process.env.REACT_APP_URBANO_USER_API}/permissions?${params}`,
      {
        headers: authService.authHeader(),
      }
    );
    return response.json();
  }

  async getRoles() {
    const params = encodeURI(`page=1&limit=1000`);
    const response = await fetch(
      `${process.env.REACT_APP_URBANO_USER_API}/permissions?${params}`,
      {
        headers: authService.authHeader(),
      }
    );
    return response.json();
  }

  async getSystems() {
    const response = await fetch(`${process.env.REACT_APP_URBANO_USER_API}/systems`, {
      headers: authService.authHeader(),
    });
    return response.json();
  }

  async forceGetUser() {
    const userData = await userService.getUserData();
    if (!!userData && !!userData.data) {
      const user = userData.data.getUserData;
      const role = user.permissions.performance ? user.permissions.performance[0] || '' : null;
      const rolePermission = await this.getPermissions();
      const permissions =
        !!rolePermission && !!rolePermission.data && rolePermission.data.length > 0
          ? rolePermission.data
          : [];
      const idx = permissions.findIndex((per: any) => !!per && per.alias === role);
      const roleData = idx !== -1 ? permissions[idx] : null;
      return {
        ...user,
        id: parseInt(user.id, 10),
        authenticated: true,
        fullName: user.fullName,
        role: { ...roleData },
      };
    }
    return null;
  }

  async forceGetRole() {
    const userData = await userService.getUserData();
    if (!!userData && !!userData.data) {
      const user = userData.data.getUserData;
      const role = user.permissions.performance ? user.permissions.performance[0] || '' : null;
      return role;
    }
    return null;
  }

  can(roles: Role[], action: string, permission: string) {
    return (
      !!roles && roles.some((role) => this.hasPermission(role.alias, action, permission))
    );
  }

  hasPermission(role, action, permission) {
    const hasPermission =
      !!permissions[role] &&
      !!permissions[role][action] &&
      permissions[role][action].some((rolePermission) => rolePermission === permission);
    return hasPermission;
  }
}

const userService = new UserService();
export default userService;
