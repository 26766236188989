import { PeriodState } from './period.type';
import {
  PeriodActions,
  PERIOD_LIST_GET,
  PERIOD_ID_GET,
  PERIOD_REQUEST,
  PERIOD_FEEDBACK_CREATE,
  PERIOD_FEEDBACK_EDIT,
} from './period.action.type';

const defaultState: PeriodState = {
  periodFeedback: null,
  period: null,
  periods: null,
  isFetching: false,
  status: null,
  message: null,
  isFeedbackConfigCompleted: false,
  shouldGoToPeriods: false,
};

export default (state = defaultState, action: PeriodActions): PeriodState => {
  switch (action.type) {
    case PERIOD_LIST_GET: {
      return {
        ...action.payload,
      };
    }
    case PERIOD_ID_GET: {
      return {
        ...action.payload,
      };
    }
    case PERIOD_REQUEST: {
      return {
        ...action.payload,
      };
    }
    case PERIOD_FEEDBACK_CREATE: {
      return {
        ...action.payload,
      };
    }
    case PERIOD_FEEDBACK_EDIT: {
      return {
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
