import {
  FeedbackActions,
  FEEDBACK_COLLABORATOR_EXPORT_PDF,
  FEEDBACK_COLLABORATOR_EXPORT_EXCEL,
  FEEDBACK_CURRENT,
  FEEDBACK_DELETE,
  FEEDBACK_HOLIDAYS,
  FEEDBACK_LEADER_EXPORT_EXCEL,
  FEEDBACK_LEADER_EXPORT_PDF,
  FEEDBACK_LIST,
  FEEDBACK_REQUEST,
  FEEDBACK_SAVED,
} from './feedback.action.type';
import { FeedbackState } from './feedback.type';

const defaultState: FeedbackState = {
  feedbacks: [],
  feedback: null,
  page: 1,
  pageCount: 1,
  total: 1,
  count: 10,
  limit: 10,
  isFetching: false,
  isActionCompleted: false,
  startDateFeedback: null,
  endDateFeedback: null,
  nroOrderFeedback: null,
  holidays: null,
};

export default (state = defaultState, action: FeedbackActions): FeedbackState => {
  switch (action.type) {
    case FEEDBACK_LIST: {
      return {
        ...action.payload,
      };
    }
    case FEEDBACK_REQUEST: {
      return {
        ...action.payload,
      };
    }
    case FEEDBACK_SAVED: {
      return {
        ...action.payload,
      };
    }
    case FEEDBACK_CURRENT: {
      return {
        ...action.payload,
      };
    }
    case FEEDBACK_DELETE: {
      return {
        ...action.payload,
      };
    }
    case FEEDBACK_HOLIDAYS: {
      return {
        ...action.payload,
      };
    }
    case FEEDBACK_LEADER_EXPORT_EXCEL: {
      return {
        ...action.payload,
      };
    }
    case FEEDBACK_LEADER_EXPORT_PDF: {
      return {
        ...action.payload,
      };
    }
    case FEEDBACK_COLLABORATOR_EXPORT_PDF: {
      return {
        ...action.payload,
      };
    }
    case FEEDBACK_COLLABORATOR_EXPORT_EXCEL: {
      return {
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
