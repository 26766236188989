import { PerformanceActions, PERFORMANCE_VALIDATE } from './performance.action.type';
import { PerformanceState } from './performance.type';
import {
  PERFORMANCE_LIST_GET,
  PERFORMANCE_REQUEST,
  PERFORMANCE_EXPORT_PDF,
  PERFORMANCE_EXPORT_EXCEL,
} from './performance.action.type';

const defaultState: PerformanceState = {
  performance: null,
  performances: [],
  isFetching: false,
  status: null,
  message: null,
  performanceById: null,
};

export default (state = defaultState, action: PerformanceActions): PerformanceState => {
  switch (action.type) {
    case PERFORMANCE_LIST_GET: {
      return {
        ...action.payload,
      };
    }
    case PERFORMANCE_REQUEST: {
      return {
        ...action.payload,
      };
    }
    case PERFORMANCE_VALIDATE: {
      return {
        ...action.payload,
      };
    }
    case PERFORMANCE_EXPORT_PDF: {
      return {
        ...action.payload,
      };
    }
    case PERFORMANCE_EXPORT_EXCEL: {
      return {
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
