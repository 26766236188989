import authService from './auth.service';
import notificationService from './notifications.service';
import { PerformanceDto } from '../store/performance/performance.type';

class PerfomanceService {
  private createFilterRevision(
    periodId: string | null = null,
    collaboratorId: string | null = null,
    generalState: string | null = null,
    notStateRejected: string | null = null
  ) {
    let filter = '';
    if (!!collaboratorId && !!generalState) {
      filter = `id_collaborator=${collaboratorId}&generalState=${generalState}`;
    } else if (!!periodId && !!collaboratorId && !!notStateRejected) {
      filter = `id_period=${periodId}&id_collaborator=${collaboratorId}&notStateRejected=${notStateRejected}`;
    } else if (!!periodId && !!collaboratorId) {
      filter = `id_period=${periodId}&id_collaborator=${collaboratorId}`;
    } else if (!!periodId) {
      filter = `id_period=${periodId}`;
    } else if (!!collaboratorId) {
      filter = `id_collaborator=${collaboratorId}`;
    }
    return filter;
  }

  async getPerformancePendingRevision(
    periodId: string | null = null,
    collaboratorId: string | null = null
  ) {
    const filter = this.createFilterRevision(periodId, collaboratorId);
    const url = `${process.env.REACT_APP_API_HOST}/revision/pending`;
    const response = await fetch(`${url}?${filter}`, {
      headers: authService.authHeader(false),
    });
    return response.json();
  }

  async getPerformanceAllRevision(
    periodId: string | null = null,
    collaboratorId: string | null = null,
    generalState: string | null = null,
    notStateRejected: string | null = null
  ) {
    const filter = this.createFilterRevision(
      periodId,
      collaboratorId,
      generalState,
      notStateRejected
    );
    const url = `${process.env.REACT_APP_API_HOST}/revision/all`;
    const response = await fetch(`${url}?${filter}`, {
      headers: authService.authHeader(),
    });
    return response.json();
  }

  async getPerformancesByPeriod(periodId: string) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/performances?id_period=${periodId}`,
      {
        headers: authService.authHeader(),
      }
    );
    return response.json();
  }

  async getPerformancesByPeriodAndCollaborator(periodId: string, collaboratorId: number) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/performances?id_period=${periodId}&id_collaborator=${collaboratorId}`,
      {
        headers: authService.authHeader(),
      }
    );
    return response.json();
  }

  async getPerformanceById(id: string): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/performances/${id}`, {
      headers: authService.authHeader(),
    });
    return response.json();
  }

  async deletePerformance(id: string): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/performances/${id}`, {
      method: 'DELETE',
      headers: authService.authHeader(),
    });
    return response.json();
  }

  async updatePerformance(
    id: string,
    objective: string,
    weight: number,
    endDate: Date,
    freeField: string | null,
    experience: string | null,
    social: string | null,
    personal: string | null,
    competenceId: string | null
  ): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/performances/${id}`, {
      method: 'PUT',
      headers: authService.authHeader(),
      body: JSON.stringify({
        objective: objective,
        weight: weight,
        endDate: endDate,
        freeField: freeField,
        experience: experience,
        social: social,
        personal: personal,
        competence: competenceId,
      }),
    });
    return response.json();
  }

  async annulledPerformance(id: string): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/performances/annulled/${id}`, {
      method: 'PUT',
      headers: authService.authHeader(),
    });
    return response.json();
  }

  async createPerformance(performance: PerformanceDto): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/performances`, {
      method: 'POST',
      headers: authService.authHeader(),
      body: JSON.stringify({
        performanceType: performance.type,
        competence: performance.competenceId,
        freeField: performance.freeField,
        experience: performance.experience,
        social: performance.social,
        personal: performance.personal,
        objective: performance.objective,
        endDate: performance.endDate,
        weight: performance.weight,
        period: parseInt(performance.periodId),
      }),
    });
    return response.json();
  }

  async sendPerformanceToReview(periodId: string) {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/revision`, {
      method: 'POST',
      headers: authService.authHeader(),
      body: JSON.stringify({ period: periodId }),
    });
    const resp: any = response.json();
    console.log(resp);
    if (!!resp.status && (parseInt(resp.status) === 200 || parseInt(resp.status) === 204)) {
      notificationService.notifyToTheLider('Tenes objetivos pendientes de aprobar');
    }
    return resp;
  }

  async approvePerformance(periodId: string, collaboratorId: string, idsPerformanceSelect: any[]) {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/revision/approve`, {
      method: 'POST',
      headers: authService.authHeader(),
      body: JSON.stringify({
        id_period: periodId,
        id_collaborator: collaboratorId,
        ids_performance: idsPerformanceSelect,
      }),
    });
    const resp: any = response.json();
    if (!!resp.status && (parseInt(resp.status) === 200 || parseInt(resp.status) === 204)) {
      notificationService.notifyToTheCollaborator(collaboratorId, 'Tus objetivos fueron aprobados');
    }
    return resp;
  }

  async rejectPerformance(periodId: string, collaboratorId: string, idsPerformanceSelect: any[]) {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/revision/reject`, {
      method: 'POST',
      headers: authService.authHeader(),
      body: JSON.stringify({
        id_period: periodId,
        id_collaborator: collaboratorId,
        ids_performance: idsPerformanceSelect,
      }),
    });
    const resp: any = response.json();
    if (!!resp.status && (parseInt(resp.status) === 200 || parseInt(resp.status) === 204)) {
      notificationService.notifyToTheCollaborator(
        collaboratorId,
        'Tus objetivos fueron rechazados'
      );
    }
    return resp;
  }

  async exportPerformancePdf(periodId: string, hiddenColumnNames: string[]) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/performances/export-pdf/${periodId}?hiddenColumnNames=${hiddenColumnNames}`,
      {
        headers: authService.authHeader(),
      }
    );
    return response.json();
  }

  async exportPerformanceExcel(periodId: string, hiddenColumnNames: string[]) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/performances/export-excel/${periodId}?hiddenColumnNames=${hiddenColumnNames}`,
      {
        headers: authService.authHeader(),
      }
    );
    return response.json();
  }
}

const perfomanceService = new PerfomanceService();
export default perfomanceService;
