import { NotificationState } from './notification.type';

export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const NOTIFICATION_REQUEST = 'NOTIFICATION_REQUEST';

export interface NotificationListAction {
  type: typeof NOTIFICATION_LIST;
  payload: NotificationState;
}

export interface NotificationRequestAction {
  type: typeof NOTIFICATION_REQUEST;
  payload: NotificationState;
}

export type NotificationActionTypes = NotificationListAction | NotificationRequestAction;

export type NotificationActions = NotificationActionTypes;
