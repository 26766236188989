import {
  PERFORMANCE_LIST,
  PERFORMANCE_REQUEST,
  PerformanceReportActions,
  STATUSES_LIST,
  TYPES_LIST,
  PERFORMANCE_REPORT_EXPORT_EXCEL,
  PERFORMANCE_REPORT_EXPORT_PDF,
} from './performance-report.action.type';
import { PerformanceReportState } from './performance-report.type';

const defaultState: PerformanceReportState = {
  performances: [],
  isFetching: false,
  error: null,
  page: 1,
  pageCount: 1,
  total: 1,
  count: 10,
  limit: 10,
  filters: [],
  sorting: [],
  statuses: [],
  types: [],
  message: null,
};

export default (state = defaultState, action: PerformanceReportActions): PerformanceReportState => {
  switch (action.type) {
    case PERFORMANCE_LIST: {
      return {
        ...action.payload,
      };
    }
    case PERFORMANCE_REQUEST: {
      return {
        ...action.payload,
      };
    }
    case PERFORMANCE_REPORT_EXPORT_EXCEL: {
      return {
        ...action.payload,
      };
    }
    case PERFORMANCE_REPORT_EXPORT_PDF: {
      return {
        ...action.payload,
      };
    }
    case STATUSES_LIST: {
      return {
        ...action.payload,
      };
    }
    case TYPES_LIST: {
      return {
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
