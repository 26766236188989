import { combineReducers } from 'redux';
import user from './user/user.reducer';
import router from './router/router.reducer';
import period from './period/period.reducer';
import competence from './competence/compentence.reducer';
import performance from './performance/performance.reduce';
import notification from './notification/notification.reducer';
import areaObjective from './area-objective/area-objective.reducer';
import costCenter from './cost-center/cost-center.reducer';
import companyObjective from './company-objective/company-objective.reducer';
import feedback from './feedback/feedback.reducer';
import feedbackStatus from './feedback-status/feedback-status.reducer';
import performanceReport from './performance-report/performance-report.reducer';
import selfAppraisal from './self-appraisal/self-appraisal.reducer';

const appReducer = combineReducers({
  user,
  router,
  period,
  competence,
  performance,
  notification,
  companyObjective,
  feedbackStatus,
  feedback,
  areaObjective,
  costCenter,
  performanceReport,
  selfAppraisal,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
