import {
  UserActions,
  GET_ALL_COLLABORATOR,
  USER_REQUEST, 
  USER_RESPONSE,
  IS_LIDER,
} from './user.action.type';
import { UserState } from './user.types';

const defaultState: UserState = {
  rolePermissions: [],
  shouldCallLogoutUser: false,
  currentUser: null,
  collaborators: [],
  user: {
    id: null,
    email: null,
  },
  error: null,
  isFetching: false,
  authenticated: false,
};

export default (state = defaultState, action: UserActions): UserState => {
  switch (action.type) {
    case USER_REQUEST: {
      return { ...action.payload };
    }
    case USER_RESPONSE: {
      return { ...action.payload };
    }
    case GET_ALL_COLLABORATOR: {
      return {
        ...action.payload,
      };
    }
    case IS_LIDER: {
      return {
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
