import { format } from 'date-fns';
import { es } from 'date-fns/locale';

export const formatStringDate = (date: any) => {
  const splittedDate = date.split('-');
  const day = splittedDate[2].split('T')[0];
  return [day, splittedDate[1], splittedDate[0]].join('/');
};

export const formatDate = (date: Date, includeHour = true) => {
  if (!!date) {
    const YY = date.getFullYear();
    let MM: any = date.getMonth() + 1;
    if (MM < 10) MM = '0' + MM;
    let DD: any = date.getDate();
    if (DD < 10) DD = '0' + DD;

    if (includeHour) {
      let hh: any = date.getHours();
      if (hh < 10) hh = '0' + hh;
      let mm: any = date.getMinutes();
      if (mm < 10) mm = '0' + mm;
      let ss: any = date.getSeconds();
      if (ss < 10) ss = '0' + ss;
      return `${DD}/${MM}/${YY} ${hh}:${mm}:${ss}`;
    } else {
      return `${DD}/${MM}/${YY}`;
    }
  }
  return null;
};

export const formatExtenderDay = (dateString: string | null | undefined) => {
  let formattedDate;
  try {
    const date = convertStringToDate(dateString);
    if (!!date) {
      formattedDate = format(date, 'eeee, dd MMMM yyyy', { locale: es });
    }
  } catch (e) {
    console.log(e);
  }
  return formattedDate;
};

export const formatExtenderDayTime = (dateString: any) => {
  let formattedDate;
  try {
    if (!!dateString) {
      const date = new Date(dateString);
      formattedDate = format(date, 'dd MMMM yyyy HH:mm:ss', { locale: es });
    }
  } catch (e) {
    console.log(e);
  }
  return formattedDate;
};

export const formatDateString = (dateString: string | null | undefined) => {
  if (!!dateString) {
    return formatDate(new Date(dateString), false);
  }
  return null;
};

export const convertStringToDate = (dateString: string | null | undefined) => {
  if (!!dateString) {
    return convertDateFromApi(dateString);
  }
  return null;
};

export const convertStringToDateTime = (dateString: string | null | undefined) => {
  if (!!dateString) {
    return convertDateTimeFromApi(dateString);
  }
  return null;
};

export const formatDateHyphen = (date: Date | null) => {
  if (!!date) {
    const YY = date.getFullYear();
    let MM: any = date.getMonth() + 1;
    if (MM < 10) MM = '0' + MM;
    let DD: any = date.getDate();
    if (DD < 10) DD = '0' + DD;
    return `${YY}-${MM}-${DD}`;
  }
  return null;
};

const convertDateFromApi = (dateStr: string) => {
  try {
    const fullDate = dateStr.split('T')[0];
    const date = fullDate.split('-');
    const month = parseInt(date[1]) - 1;
    return new Date(parseInt(date[0], 10), month, parseInt(date[2], 10), 0, 0);
  } catch (err) {
    console.error(err);
  }
  return null;
};

const convertDateTimeFromApi = (dateStr: string) => {
  try {
    const fullDate = dateStr.split('T')[0];
    const fullDay = dateStr.split('T')[1];
    const day = fullDay.split(':');
    const date = fullDate.split('-');
    const month = parseInt(date[1]) - 1;
    return new Date(
      parseInt(date[0]),
      month,
      parseInt(date[2]),
      parseInt(day[0]),
      parseInt(day[1]),
      parseInt(day[2])
    );
  } catch (err) {
    console.error(err);
  }
  return null;
};
