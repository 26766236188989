import React, { RefObject, createRef } from 'react';
import { IonContent, IonItem, IonList, IonMenu } from '@ionic/react';
import { connect } from 'react-redux';
import { RootState } from '../../store/root-reducer';
import { withRouter } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ListAltIcon from '@material-ui/icons/ListAlt';
import QuizIcon from '@material-ui/icons/QuestionAnswer';

import AssessmentIcon from '@material-ui/icons/Assessment';
import FeedbackIcon from '@material-ui/icons/Feedback';

import Logo from '../../assets/images/logo-urbano-blanc.svg';
import FactCheckIcon from '../../assets/images/fact_check_white_24dp.svg';

import './SideMenu.css';
import DinoVersion from '../dino-components/DinoVersion/DinoVersion';
import { Icon } from '@material-ui/core';

type Props = LinkStateProps;

export class SideMenu extends React.Component<Props> {
  menuRef: RefObject<HTMLIonMenuElement>;
  hasDetails = false;

  constructor(props: Props) {
    super(props);
    this.menuRef = createRef<HTMLIonMenuElement>();
  }

  closeMenu() {
    this.menuRef && this.menuRef.current && this.menuRef.current.close();
  }

  render() {
    // @ts-ignore
    const currentPath = this.props.location.pathname;
    return (
      <IonMenu
        contentId="menuContent"
        class={'SideMenuPerfomance'}
        ref={this.menuRef}
        disabled={!this.props.authenticated}
      >
        <IonContent>
          <img src={Logo} alt={'Urbano Logo'} className={'logo'} />
          <IonList>
            <IonItem
              button
              detail={this.hasDetails}
              routerLink={`/main?t=${new Date().getTime()}`}
              class={currentPath === '/main' ? 'isActive' : ''}
              onClick={(e) => this.closeMenu()}
            >
              <HomeIcon className={'SideMenuPerfomance__icon'} />
              Inicio
            </IonItem>
            <IonItem
              button
              detail={this.hasDetails}
              routerLink={`/periods?t=${new Date().getTime()}`}
              class={currentPath === '/periods' ? 'isActive' : ''}
              onClick={(e) => this.closeMenu()}
            >
              <EventAvailableIcon className={'SideMenuPerfomance__icon'} />
              Períodos
            </IonItem>
            <IonItem
              button
              detail={this.hasDetails}
              routerLink={`/competences?t=${new Date().getTime()}`}
              class={currentPath === '/competences' ? 'isActive' : ''}
              onClick={(e) => this.closeMenu()}
            >
              <DirectionsRunIcon className={'SideMenuPerfomance__icon'} />
              Competencias
            </IonItem>
            <IonItem
              button
              detail={this.hasDetails}
              routerLink={`/feedbacks?t=${new Date().getTime()}`}
              class={currentPath === '/feedbacks' ? 'isActive' : ''}
              onClick={(e) => this.closeMenu()}
            >
              <FeedbackIcon className={'SideMenuPerfomance__icon'} />
              Mis Feedbacks
            </IonItem>
            {this.props.isAdmin && (
              <IonItem
                button
                detail={this.hasDetails}
                routerLink={`/performance-report?t=${new Date().getTime()}`}
                class={currentPath === '/performance-report' ? 'isActive' : ''}
                onClick={(e) => this.closeMenu()}
              >
                <AssessmentIcon className={'SideMenuPerfomance__icon'} />
                Reporte de Objetivos
              </IonItem>
            )}
            {this.props.isAdmin && (
              <IonItem
                button
                detail={this.hasDetails}
                routerLink={`/company-objectives?t=${new Date().getTime()}`}
                class={currentPath === '/company-objectives' ? 'isActive' : ''}
                onClick={(e) => this.closeMenu()}
              >
                <AssignmentIcon className={'SideMenuPerfomance__icon'} />
                Objetivos Empresa
              </IonItem>
            )}
            {(this.props.isAdmin || this.props.isManager || this.props.isLider) &&
              this.props.hasCCTango && (
                <IonItem
                  button
                  detail={this.hasDetails}
                  routerLink={`/area-objectives?t=${new Date().getTime()}`}
                  class={currentPath === '/area-objectives' ? 'isActive' : ''}
                  onClick={(e) => this.closeMenu()}
                >
                  <ListAltIcon className={'SideMenuPerfomance__icon'} />
                  Objetivos Área
                </IonItem>
              )}

            {
              //TODO: Agregar restricciones de permisos sobre el boton de autoevaluacion
            }
            <IonItem
              button
              detail={this.hasDetails}
              routerLink={`/self-appraisal?t=${new Date().getTime()}`}
              class={currentPath === '/self-appraisal' ? 'isActive' : ''}
              onClick={(e) => this.closeMenu()}
            >
              <QuizIcon className={'SideMenuPerfomance__icon'} />
              Autoevaluación
            </IonItem>
            {this.props.isLider && (
              <IonItem
                button
                detail={this.hasDetails}
                routerLink={`/appraisal?t=${new Date().getTime()}`}
                class={currentPath === '/appraisal' ? 'isActive' : ''}
                onClick={(e) => this.closeMenu()}
              >
                <Icon className={'SideMenuPerfomance__icon'}>
                  <img src={FactCheckIcon} alt={'Evaluaciones'} />
                </Icon>
                Evaluaciones
              </IonItem>
            )}
          </IonList>
          <DinoVersion />
        </IonContent>
      </IonMenu>
    );
  }
}

interface LinkStateProps {
  authenticated: boolean;
  isAdmin: boolean;
  isLider: boolean;
  hasCCTango: boolean;
  isManager: boolean;
  user: any;
}

const mapState = (state: RootState): LinkStateProps => {
  const { user, authenticated } = state.user;
  return {
    authenticated: authenticated ? authenticated : false,
    isAdmin: !!user ? !!user.isAdmin : false,
    isLider: !!user && !!user.isLeader ? user.isLeader : false,
    hasCCTango: !!user && !!user.costCenter,
    isManager: !!user && !!user.isManager,
    user,
  };
};

const SideMenuWithRouter = withRouter<any, any>((props: any) => <SideMenu {...props} />);

export default connect(mapState)(SideMenuWithRouter);
