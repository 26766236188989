import {
  CompanyObjectiveActions,
  COMPANY_OBJECTIVE_CREATED_RESPONSE,
  COMPANY_OBJECTIVE_ID_GET,
  COMPANY_OBJECTIVE_LIST,
  COMPANY_OBJECTIVE_REQUEST,
  COMPANY_OBJECTIVE_UPDATED_RESPONSE,
} from './company-objective.action.type';
import { CompanyObjectiveState } from './company-objective.type';

const defaultState: CompanyObjectiveState = {
  companyObjective: null,
  companyObjectives: [],
  isFetching: false,
  page: 1,
  pageCount: 1,
  total: 1,
  count: 10,
  limit: 10,
  isActionCompleted: false,
  filters: [],
  sorting: [],
  shouldGoToObjetives: false,
  status: null,
};

export default (state = defaultState, action: CompanyObjectiveActions): CompanyObjectiveState => {
  switch (action.type) {
    case COMPANY_OBJECTIVE_LIST: {
      return {
        ...action.payload,
      };
    }
    case COMPANY_OBJECTIVE_REQUEST: {
      return {
        ...action.payload,
      };
    }
    case COMPANY_OBJECTIVE_CREATED_RESPONSE: {
      return {
        ...action.payload,
      };
    }
    case COMPANY_OBJECTIVE_ID_GET: {
      return {
        ...action.payload,
      };
    }
    case COMPANY_OBJECTIVE_UPDATED_RESPONSE: {
      return {
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
