import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import authService from './auth.service';

const OPERATIONS = {
  contains: CondOperator.CONTAINS_LOW,
  equal: CondOperator.EQUALS,
  greaterThan: CondOperator.GREATER_THAN,
  greaterThanOrEqual: CondOperator.GREATER_THAN_EQUALS,
  lessThan: CondOperator.LOWER_THAN,
  lessThanOrEqual: CondOperator.LOWER_THAN_EQUALS,
};

class CostCenterService {
  private createQueryFilter(
    page: number = 10,
    count: number = 10,
    filters: any[] = [],
    sorting: any[] = []
  ) {
    const qb = RequestQueryBuilder.create().setPage(page).setLimit(count);
    if (filters.length) {
      filters.forEach((filterData) => {
        const value = filterData.value;
        const operator = OPERATIONS[filterData.operation || 'equal'];
        let skip = value === -1;
        let filterName = filterData.columnName;
        switch (filterName) {
          case 'activeStr':
            if (value === -1) {
              skip = true;
              break;
            }
            filterName = 'active';
            break;
        }
        if (!skip) {
          qb.setFilter({
            field: filterName,
            operator: operator,
            value: value,
          });
        }
      });
    }
    if (sorting.length > 0) {
      const sortBy: any[] = [];
      for (const sort of sorting) {
        sortBy.push({ field: sort.columnName, order: sort.direction.toUpperCase() });
      }
      qb.sortBy(sortBy);
    }
    return qb.query();
  }

  async getCostCenters(
    page: number = 1,
    count: number = 10,
    filters: any[] = [],
    sorting: any[] = []
  ) {
    const query = this.createQueryFilter(page, count, filters, sorting);
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/cost-centers?${query}`, {
      headers: authService.authHeader(),
    });
    return response.json();
  }

  async getCostCenterById(id: string) {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/cost-centers/${id}`, {
      method: 'GET',
      headers: authService.authHeader(),
    });
    return response.json();
  }

  async getCostCenterByCode(code: string) {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/cost-centers/costCenterByCode/${code}`, {
      method: 'GET',
      headers: authService.authHeader(),
    });
    return response.json();
  }
}

const areaObjectiveService = new CostCenterService();
export default areaObjectiveService;
