import { Dispatch } from 'react';

import { PeriodState } from './period.type';
import { PERIOD_LIST_GET, PERIOD_REQUEST, PERIOD_ID_GET } from './period.action.type';
import { PeriodActions } from './period.action.type';
import periodService from '../../services/period.service';
import { RootState } from '..';
import companyObjectiveService from '../../services/company-objective.service';

const periodList = (periodState: PeriodState): PeriodActions => {
  return {
    type: PERIOD_LIST_GET,
    payload: periodState,
  };
};

const periodById = (periodState: PeriodState): PeriodActions => {
  return {
    type: PERIOD_ID_GET,
    payload: periodState,
  };
};

const periodRequest = (periodState: PeriodState): PeriodActions => {
  return {
    type: PERIOD_REQUEST,
    payload: periodState,
  };
};

export const getPeriodList = (active: boolean | null = null) => {
  return async (dispatch: Dispatch<PeriodActions>, getState: () => RootState) => {
    dispatch(
      periodRequest({
        ...getState().period,
        isFetching: false,
      })
    );
    const response: any = await periodService.getPeriods(active);
    if (!!response.data) {
      response.data = response.data.sort((n1: any, n2: any) => {
        if (Number(n2.period) > Number(n1.period)) {
          return 1;
        } else if (Number(n2.period) < Number(n1.period)) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    dispatch(
      periodList({
        ...getState().period,
        isFetching: false,
        status: response.status,
        message: response.message,
        periods: response.data,
      })
    );
  };
};

export const getAreaObjectiveByPeriod = (periodId: string) => {
  return async (dispatch: Dispatch<PeriodActions>, getState: () => RootState) => {
    dispatch(
      periodRequest({
        ...getState().period,
        isFetching: true,
      })
    );
    const response: any = await periodService.getAreaObjectiveByPeriod(periodId);
    dispatch(
      periodById({
        ...getState().period,
        isFetching: false,
        status: response.status,
        message: response.message,
        areaObjective: response.data,
      })
    );
  };
};

export const getCompanyObjectiveByPeriod = (periodId: string) => {
  return async (dispatch: Dispatch<PeriodActions>, getState: () => RootState) => {
    dispatch(
      periodRequest({
        ...getState().period,
        isFetching: true,
      })
    );
    const filters = [
      { columnName: 'period.id', operation: 'equal', value: periodId },
      { columnName: 'active', operation: 'equal', value: true },
    ];
    const response: any = await companyObjectiveService.getCompanyObjectives(1, 1, filters, []);
    dispatch(
      periodById({
        ...getState().period,
        isFetching: false,
        status: response.status,
        message: response.message,
        companyObjective: response.data.length > 0 ? response.data[0] : null,
      })
    );
  };
};

export const getPeriodById = (id: string) => {
  return async (dispatch: Dispatch<PeriodActions>, getState: () => RootState) => {
    dispatch(
      periodRequest({
        ...getState().period,
        isFetching: true,
      })
    );
    const response: any = await periodService.getPeriodById(id);
    dispatch(
      periodById({
        ...getState().period,
        isFetching: false,
        status: response.status,
        message: response.message,
        period: response.data,
        periodFeedback: response.data,
      })
    );
  };
};

export const updatePeriod = (
  id: string | null,
  period: Date | null,
  active: boolean | null,
  startObjective: Date | null,
  endObjective: Date | null,
  startReturn: Date | null,
  endReturn: Date | null,
  startClosing: Date | null,
  endClosing: Date | null
) => {
  return async (dispatch: Dispatch<PeriodActions>, getState: () => RootState) => {
    dispatch(
      periodRequest({
        ...getState().period,
        isFetching: true,
      })
    );
    const response: any = await periodService.updatePeriod(
      id,
      period,
      active,
      startObjective,
      endObjective,
      startReturn,
      endReturn,
      startClosing,
      endClosing
    );
    const responseList: any = await periodService.getPeriods(true);
    dispatch(
      periodList({
        isFetching: false,
        status: response.status,
        message: response.message,
        period: null,
        periods: responseList.data,
      })
    );
  };
};

export const toggleActivation = (id: string | null, active: boolean | null) => {
  return async (dispatch: Dispatch<PeriodActions>, getState: () => RootState) => {
    dispatch(
      periodRequest({
        ...getState().period,
        isFetching: true,
      })
    );
    const response: any = await periodService.toggleActivation(id, active);
    const responseList: any = await periodService.getPeriods(false);
    dispatch(
      periodList({
        isFetching: false,
        status: response.status,
        message: response.message,
        period: null,
        periods: responseList.data,
      })
    );
  };
};

export const createPeriod = (
  period: Date | null,
  status: boolean | null,
  startObjective: Date | null,
  endObjective: Date | null,
  startReturn: Date | null,
  endReturn: Date | null,
  startClosing: Date | null,
  endClosing: Date | null
) => {
  return async (dispatch: Dispatch<PeriodActions>, getState: () => RootState) => {
    dispatch(
      periodRequest({
        ...getState().period,
        isFetching: true,
      })
    );
    const response: any = await periodService.createPeriod(
      period,
      status,
      startObjective,
      endObjective,
      startReturn,
      endReturn,
      startClosing,
      endClosing
    );
    const responseList: any = await periodService.getPeriods(true);
    dispatch(
      periodList({
        isFetching: false,
        status: response.status,
        message: response.message,
        period: response.data,
        periods: responseList.data,
      })
    );
  };
};

export const updateFeedbackConfigCompleted = (action: boolean) => {
  return (dispatch: Dispatch<PeriodActions>, getState: () => RootState) => {
    dispatch(
      periodList({
        ...getState().period,
        isFeedbackConfigCompleted: action,
      })
    );
  };
};

export const cleanPeriod = () => {
  return (dispatch: Dispatch<PeriodActions>, getState: () => RootState) => {
    dispatch(
      periodRequest({
        ...getState().period,
        period: null,
        periodFeedback: null,
      })
    );
  };
};

export const updateShouldGoToPeriods = (action: boolean) => {
  return (dispatch: Dispatch<PeriodActions>, getState: () => RootState) => {
    dispatch(
      periodList({
        ...getState().period,
        shouldGoToPeriods: action,
      })
    );
  };
};

export const createPeriodFeedback = (
  periodId: string | number,
  data: {
    nroOfFeedback: string;
    startDateFeedback1: Date | null;
    endDateFeedback1: Date | null;
    startDateFeedback2: Date | null;
    endDateFeedback2: Date | null;
    startDateFeedback3: Date | null;
    endDateFeedback3: Date | null;
    startDateFeedback4: Date | null;
    endDateFeedback4: Date | null;
    startDateFeedback5: Date | null;
    endDateFeedback5: Date | null;
    startDateFeedback6: Date | null;
    endDateFeedback6: Date | null;
    startDateFeedback7: Date | null;
    endDateFeedback7: Date | null;
    startDateFeedback8: Date | null;
    endDateFeedback8: Date | null;
    startDateFeedback9: Date | null;
    endDateFeedback9: Date | null;
    startDateFeedback10: Date | null;
    endDateFeedback10: Date | null;
    startDateFeedback11: Date | null;
    endDateFeedback11: Date | null;
    startDateFeedback12: Date | null;
    endDateFeedback12: Date | null;
  }
) => {
  return async (dispatch: Dispatch<PeriodActions>, getState: () => RootState) => {
    dispatch(
      periodRequest({
        ...getState().period,
        isFetching: true,
      })
    );
    const response: any = await periodService.createPeriodFeedback(periodId, data);
    const shouldGoToPeriods = !response.statusCode;
    let isFeedbackConfigCompleted = false;
    if (!response.statusCode) {
      isFeedbackConfigCompleted = true;
    }
    dispatch(
      periodList({
        isFetching: false,
        isFeedbackConfigCompleted: isFeedbackConfigCompleted,
        shouldGoToPeriods: shouldGoToPeriods,
        period: null,
      })
    );
  };
};

export const getPeriodListByUserId = (userId: number | null, active: boolean | null = null) => {
  return async (dispatch: Dispatch<PeriodActions>, getState: () => RootState) => {
    dispatch(
      periodRequest({
        ...getState().period,
        isFetching: false,
      })
    );
    const response: any = await periodService.getPeriodsByUserId(userId, active);
    if (!!response.data) {
      response.data = response.data.sort((n1: any, n2: any) => {
        if (Number(n2.period) > Number(n1.period)) {
          return 1;
        } else if (Number(n2.period) < Number(n1.period)) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    dispatch(
      periodList({
        ...getState().period,
        isFetching: false,
        status: response.status,
        message: response.message,
        periods: response.data,
      })
    );
  }
};