import { Dispatch } from 'react';

import { RootState } from '..';
import {
  NOTIFICATION_LIST,
  NotificationActions,
  NOTIFICATION_REQUEST,
} from './notification.action.type';
import { NotificationState } from './notification.type';
import notificationService from '../../services/notifications.service';

const notificationList = (notification: NotificationState): NotificationActions => {
  return {
    type: NOTIFICATION_LIST,
    payload: notification,
  };
};

const notificationRequest = (notification: NotificationState): NotificationActions => {
  return {
    type: NOTIFICATION_REQUEST,
    payload: notification,
  };
};

export const getNotifications = () => {
  return async (dispatch: Dispatch<NotificationActions>, getState: () => RootState) => {
    dispatch(
      notificationRequest({
        ...getState().notification,
        isFetching: true,
      })
    );
    const response = await notificationService.getNotifications();
    dispatch(
      notificationList({
        isFetching: false,
        status: response.status,
        message: response.message,
        notifications: response.data,
      })
    );
  };
};

export const deleteNotificationToken = () => {
  return async (dispatch: Dispatch<NotificationActions>, getState: () => RootState) => {
    dispatch(
      notificationRequest({
        ...getState().notification,
        isFetching: true,
      })
    );
    const response = await notificationService.deleteNotificationToken();
    dispatch(
      notificationList({
        ...getState().notification,
        isFetching: false,
        status: response.status,
        message: response.message,
      })
    );
  };
};

export const deleteNotification = (id: string) => {
  return async (dispatch: Dispatch<NotificationActions>, getState: () => RootState) => {
    dispatch(
      notificationRequest({
        ...getState().notification,
        isFetching: true,
      })
    );
    const response = await notificationService.deleteNotification(id);
    const responseList = await notificationService.getNotifications();
    dispatch(
      notificationList({
        ...getState().notification,
        isFetching: false,
        status: response.status,
        message: response.message,
        notifications: responseList.data,
      })
    );
  };
};
