import { SelfAppraisalState } from './self-appraisal.type';
import {
  CREATE_SELF_APPRAISAL,
  SelfAppraisalActions,
  APPRAISAL_STATUS_BY_LEADER_GET,
  SELF_APPRAISAL_LIST_GET,
  UPDATE_SELF_APPRAISAL,
  CONFIRM_SELF_APPRAISAL,
  EXPORT_SELF_APPRAISAL_PDF,
  EXPORT_APPRAISAL_EXCEL,
} from './self-appraisal.action.type';

const defaultState: SelfAppraisalState = {
  isFetching: false,
  status: null,
  message: null,
  selfAppraisals: [],
  isFetchingByLeader: false,
  statusByLeader: null,
  messageByLeader: null,
  appraisalStatusByLeader: [],
  isFetchingCollaboratorsAppraisal: false,
  statusCollaboratorAppraisal: null,
  messageCollaboratorAppraisal: null,
  collaboratorsAppraisal: [],
};

export default (state = defaultState, action: SelfAppraisalActions): SelfAppraisalState => {
  switch (action.type) {
    case SELF_APPRAISAL_LIST_GET: {
      return {
        ...action.payload,
      };
    }
    case CREATE_SELF_APPRAISAL: {
      return {
        ...action.payload,
      };
    }
    case UPDATE_SELF_APPRAISAL: {
      return {
        ...action.payload,
      };
    }
    case CONFIRM_SELF_APPRAISAL: {
      return {
        ...action.payload,
      };
    }
    case APPRAISAL_STATUS_BY_LEADER_GET: {
      return {
        ...action.payload,
      };
    }
    case EXPORT_SELF_APPRAISAL_PDF: {
      return {
        ...action.payload,
      };
    }
    case EXPORT_APPRAISAL_EXCEL: {
      return {
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
