import {
  CostCenterActions,
  COST_CENTER_ID_GET,
  COST_CENTER_LIST,
  COST_CENTER_REQUEST,
} from './cost-center.action.type';
import { CostCenterState } from './cost-center.type';

const defaultState: CostCenterState = {
  costCenter: null,
  costCenters: [],
  isFetching: false,
  page: 1,
  pageCount: 1,
  total: 1,
  count: 10,
  limit: 10,
  filters: [],
  sorting: [],
  status: null,
};

export default (state = defaultState, action: CostCenterActions): CostCenterState => {
  switch (action.type) {
    case COST_CENTER_LIST: {
      return {
        ...action.payload,
      };
    }
    case COST_CENTER_REQUEST: {
      return {
        ...action.payload,
      };
    }
    case COST_CENTER_ID_GET: {
      return {
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
