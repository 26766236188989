import { PerformanceReportState } from './performance-report.type';

export const PERFORMANCE_LIST = 'PERFORMANCE_LIST';
export const PERFORMANCE_REQUEST = 'PERFORMANCE_REQUEST';
export const STATUSES_LIST = 'STATUSES_LIST';
export const TYPES_LIST = 'TYPES_LIST';
export const PERFORMANCE_REPORT_EXPORT_EXCEL = 'PERFORMANCE_REPORT_EXPORT_EXCEL';
export const PERFORMANCE_REPORT_EXPORT_PDF = 'PERFORMANCE_REPORT_EXPORT_PDF';

export interface PerformanceReportExportPdfAction {
  type: typeof PERFORMANCE_REPORT_EXPORT_PDF;
  payload: PerformanceReportState;
}
export interface PerformanceReportExportExcelAction {
  type: typeof PERFORMANCE_REPORT_EXPORT_EXCEL;
  payload: PerformanceReportState;
}
export interface PerformanceListAction {
  type: typeof PERFORMANCE_LIST;
  payload: PerformanceReportState;
}

export interface PerformanceRequestAction {
  type: typeof PERFORMANCE_REQUEST;
  payload: PerformanceReportState;
}

export interface StatusesListAction {
  type: typeof STATUSES_LIST;
  payload: PerformanceReportState;
}

export interface TypesListAction {
  type: typeof TYPES_LIST;
  payload: PerformanceReportState;
}

export type PerformanceReportActionTypes =
  | PerformanceListAction
  | PerformanceRequestAction
  | StatusesListAction
  | TypesListAction
  | PerformanceReportExportExcelAction
  | PerformanceReportExportPdfAction;

export type PerformanceReportActions = PerformanceReportActionTypes;
