import authService from './auth.service';
import { formatDateHyphen } from './utils.service';
class PeriodService {
  async getAreaObjectiveByPeriod(periodId: string) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/area-objective/period/${periodId}`,
      {
        headers: authService.authHeader(),
      }
    );
    return response.json();
  }

  async getPeriods(active: boolean | null = null) {
    let params = '';
    if (active === true || active === false) {
      params = `active=${active}`;
    }
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/periods?${params}`, {
      headers: authService.authHeader(),
    });
    return response.json();
  }

  async getPeriodById(id: string) {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/periods/${id}`, {
      headers: authService.authHeader(),
    });
    return response.json();
  }

  async getPeriodsByUserId(userId: number | null, active: boolean | null = null) {
    let params = '';
    if(!!userId) {
      params = `userId=${userId}`
    }
    if (active === true || active === false) {
      params += `&active=${active}`;
    }
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/periods?${params}`, {
      headers: authService.authHeader(),
    });
    return response.json();
  }

  async updatePeriod(
    id: string | null,
    period: Date | null,
    active: boolean | null,
    startObjective: Date | null,
    endObjective: Date | null,
    startReturn: Date | null,
    endReturn: Date | null,
    startClosing: Date | null,
    endClosing: Date | null
  ): Promise<any> {
    const year: string = period ? period.getFullYear().toString() : '';
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/periods/${id}`, {
      method: 'PUT',
      headers: authService.authHeader(),
      body: JSON.stringify({
        period: year,
        active: active,
        startDateLoadObjective: `${formatDateHyphen(startObjective)}`,
        endDateLoadObjective: `${formatDateHyphen(endObjective)}`,
        intermediateReturnStartDate: `${formatDateHyphen(startReturn)}`,
        intermediateReturnEndDate: `${formatDateHyphen(endReturn)}`,
        startDateClosingEvaluation: `${formatDateHyphen(startClosing)}`,
        endDateClosingEvaluation: `${formatDateHyphen(endClosing)}`,
      }),
    });
    return response.json();
  }

  async toggleActivation(id: string | null, active: boolean | null) {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/periods/${id}/toggle`, {
      method: 'POST',
      headers: authService.authHeader(),
      body: JSON.stringify({
        active: active,
      }),
    });
    return response.json();
  }

  async createPeriod(
    period: Date | null,
    active: boolean | null,
    startObjective: Date | null,
    endObjective: Date | null,
    startReturn: Date | null,
    endReturn: Date | null,
    startClosing: Date | null,
    endClosing: Date | null
  ): Promise<any> {
    const year: string = period ? period.getFullYear().toString() : '';
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/periods`, {
      method: 'POST',
      headers: authService.authHeader(),
      body: JSON.stringify({
        period: year,
        active: active,
        startDateLoadObjective: `${formatDateHyphen(startObjective)}`,
        endDateLoadObjective: `${formatDateHyphen(endObjective)}`,
        intermediateReturnStartDate: `${formatDateHyphen(startReturn)}`,
        intermediateReturnEndDate: `${formatDateHyphen(endReturn)}`,
        startDateClosingEvaluation: `${formatDateHyphen(startClosing)}`,
        endDateClosingEvaluation: `${formatDateHyphen(endClosing)}`,
      }),
    });
    return response.json();
  }

  async createPeriodFeedback(
    periodId: string | number,
    data: {
      nroOfFeedback: string;
      startDateFeedback1: Date | null;
      endDateFeedback1: Date | null;
      startDateFeedback2: Date | null;
      endDateFeedback2: Date | null;
      startDateFeedback3: Date | null;
      endDateFeedback3: Date | null;
      startDateFeedback4: Date | null;
      endDateFeedback4: Date | null;
      startDateFeedback5: Date | null;
      endDateFeedback5: Date | null;
      startDateFeedback6: Date | null;
      endDateFeedback6: Date | null;
      startDateFeedback7: Date | null;
      endDateFeedback7: Date | null;
      startDateFeedback8: Date | null;
      endDateFeedback8: Date | null;
      startDateFeedback9: Date | null;
      endDateFeedback9: Date | null;
      startDateFeedback10: Date | null;
      endDateFeedback10: Date | null;
      startDateFeedback11: Date | null;
      endDateFeedback11: Date | null;
      startDateFeedback12: Date | null;
      endDateFeedback12: Date | null;
    }
  ) {
    const body = {
      nroOfFeedback: data.nroOfFeedback,
      startDateFeedback1: formatDateHyphen(data.startDateFeedback1),
      endDateFeedback1: formatDateHyphen(data.endDateFeedback1),
      startDateFeedback2: formatDateHyphen(data.startDateFeedback2),
      endDateFeedback2: formatDateHyphen(data.endDateFeedback2),
      startDateFeedback3: formatDateHyphen(data.startDateFeedback3),
      endDateFeedback3: formatDateHyphen(data.endDateFeedback3),
      startDateFeedback4: formatDateHyphen(data.startDateFeedback4),
      endDateFeedback4: formatDateHyphen(data.endDateFeedback4),
      startDateFeedback5: formatDateHyphen(data.startDateFeedback5),
      endDateFeedback5: formatDateHyphen(data.endDateFeedback5),
      startDateFeedback6: formatDateHyphen(data.startDateFeedback6),
      endDateFeedback6: formatDateHyphen(data.endDateFeedback6),
      startDateFeedback7: formatDateHyphen(data.startDateFeedback7),
      endDateFeedback7: formatDateHyphen(data.endDateFeedback7),
      startDateFeedback8: formatDateHyphen(data.startDateFeedback8),
      endDateFeedback8: formatDateHyphen(data.endDateFeedback8),
      startDateFeedback9: formatDateHyphen(data.startDateFeedback9),
      endDateFeedback9: formatDateHyphen(data.endDateFeedback9),
      startDateFeedback10: formatDateHyphen(data.startDateFeedback10),
      endDateFeedback10: formatDateHyphen(data.endDateFeedback10),
      startDateFeedback11: formatDateHyphen(data.startDateFeedback11),
      endDateFeedback11: formatDateHyphen(data.endDateFeedback11),
      startDateFeedback12: formatDateHyphen(data.startDateFeedback12),
      endDateFeedback12: formatDateHyphen(data.endDateFeedback12),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/periods-feedbacks/${periodId}`,
      {
        method: 'POST',
        headers: authService.authHeader(),
        body: JSON.stringify({ ...body }),
      }
    );
    return response.json();
  }
}

const periodService = new PeriodService();
export default periodService;
