import { FeedbackStatusState } from './feedback-status.type';

export const FEEDBACK_STATUS_LIST = 'FEEDBACK_STATUS_LIST';
export const FEEDBACK_STATUS_REQUEST = 'FEEDBACK_STATUS_REQUEST';

export interface FeedbackStatusListAction {
  type: typeof FEEDBACK_STATUS_LIST;
  payload: FeedbackStatusState;
}

export interface FeedbackStatusRequestAction {
  type: typeof FEEDBACK_STATUS_REQUEST;
  payload: FeedbackStatusState;
}

export type FeedbackStatusActionTypes = FeedbackStatusListAction | FeedbackStatusRequestAction;

export type FeedbackStatusActions = FeedbackStatusActionTypes;
