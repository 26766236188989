import * as firebase from 'firebase/app';
import 'firebase/messaging';
import userService from './user.service';

import authService from './auth.service';
import { User } from '../store/user/user.types';

class NotificationService {
  private _messaging;

  init() {
    try {
      if (!this._messaging) {
        const firebaseConfig = {
          apiKey: 'AIzaSyAeDN53qUrufy1XH2CjOO-3aSy3WyzWzgI',
          authDomain: 'urbano-dev-60798.firebaseapp.com',
          databaseURL: 'https://urbano-dev-60798.firebaseio.com',
          projectId: 'urbano-dev-60798',
          storageBucket: 'urbano-dev-60798.appspot.com',
          messagingSenderId: '641772851279',
          appId: '1:641772851279:web:5d568c7282e26040061a3a',
        };
        firebase.initializeApp(firebaseConfig);
        this._messaging = firebase.messaging();
        this._messaging.usePublicVapidKey(
          'BA2xCFID_K--eNfz3ouGfpgQcPEc4qMt0J9XsrWxi9hMTwpJtG9n42IwkS4EX4ZSbxd30poXY5Uqg_M2gQVofsg'
        );
        console.log('Firebase Messaging successfully started');
        this._messaging.onTokenRefresh(() => {
          console.log('Refreshing messaging token');
          this.getMessagingToken().then((token) => {
            console.log('Refreshed token!');
          });
        });
      }
    } catch (error) {
      console.error('Error when firebase was started.', error);
    }
  }

  async getMessagingToken() {
    /**if(!this._messaging) this.init();
        let token;
        try {
            token = await this._messaging.getToken();
            if(token) {
                console.log('Fetched the following token: ', { token });
                localStorage.setItem('messaging_token', token);
                const user: any = await authService.getCurrentUser()
                await this.saveNoticationToken(user.idLegajo, token);
            } else {
                console.error('You must ask for permission before getting the token');
            }
        } catch (e) {
            console.error('An error occurred while getting the token: ', {e})
        }
        return token;**/
  }

  get messaging() {
    this._messaging = firebase.messaging();
    return this._messaging;
  }

  async getNotificationTokensByIdLegajo(idLegajo: string) {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/notification/token/${idLegajo}`,
      {
        headers: authService.authHeader(false),
      }
    );
    return response.json();
  }

  async deleteNotificationToken() {
    const messageToken = await this.getMessagingTokenStorage();
    let resp = { status: null, message: null };
    if (!!messageToken) {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/notification/token/${messageToken}`,
        {
          method: 'DELETE',
          headers: authService.authHeader(false),
        }
      );
      localStorage.removeItem('messaging_token');
      return response.json();
    }
    return resp;
  }

  async getNotifications() {
    const isAuthenticated = await authService.isAuthenticated();
    if (isAuthenticated) {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/notification`, {
        headers: authService.authHeader(false),
      });
      return response.json();
    }
    return { status: null, message: null, data: [] };
  }

  async deleteNotification(id: string) {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/notification/${id}`, {
      method: 'DELETE',
      headers: authService.authHeader(false),
    });
    return response.json();
  }

  async saveNoticationToken(idLegajo: string, token: string) {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/notification/token`, {
      method: 'POST',
      headers: authService.authHeader(false),
      body: JSON.stringify({ idLegajo: idLegajo, token: token }),
    });
    return response.json();
  }

  async notifyToTheLider(message: string) {
    const user: User = await authService.getCurrentUser();
    if (!!user.bossId) {
      const tokens = await this.getNotificationTokensByIdLegajo(user.bossId);
      if (!!tokens && !!tokens.data) {
        tokens.data.forEach((el: any) => {
          this.sendNotifactionToFirebase(message, el.token);
        });
      }
    }
  }

  async notifyToTheCollaborator(idUser: string, message: string) {
    const user: User = await userService.getUserbyId(idUser);
    if (!!user.idLegajo) {
      const tokens = await this.getNotificationTokensByIdLegajo(user.idLegajo);
      if (!!tokens && !!tokens.data) {
        tokens.data.forEach((el: any) => {
          this.sendNotifactionToFirebase(message, el.token);
        });
      }
    }
  }

  private getMessagingTokenStorage() {
    return localStorage.getItem('messaging_token');
  }

  private sendNotifactionToFirebase(message: string, toTokenUser: string) {
    /**
        fetch('https://fcm.googleapis.com/fcm/send', {
            method: 'POST',
            headers: { 'Authorization': 'key=AIzaSyDG0psK4cnPl2bSAxBciihECgTeTgYmzhU' , 'Content-Type': 'application/json'},
            body: JSON.stringify({
                to: toTokenUser,
                message: { mutable_content: true, sound: 'Tri-tone' },
                data: {
                    body: message
                }
            })
        })
        .then(() => console.log('Message was sent to firebase'))
        .catch((error: any) => console.error(error));**/
  }
}

const notificationService = new NotificationService();
export default notificationService;
