import { RouterState } from './router.type';

export const ROUTE_URL = 'ROUTE_URL';

export interface RouteURLAction {
  type: typeof ROUTE_URL;
  payload: RouterState;
}

export type RouterActionTypes = RouteURLAction;

export type RouterActions = RouterActionTypes;
