import { Dispatch } from 'react';

import performanceService from '../../services/performance.service';
import { RootState } from '..';
import { Period } from '../../store/period/period.type';
import { PerformanceDto, PerformanceState } from './performance.type';
import {
  PerformanceActions,
  PERFORMANCE_REQUEST,
  PERFORMANCE_LIST_GET,
  PERFORMANCE_VALIDATE,
  PERFORMANCE_EXPORT_PDF,
  PERFORMANCE_EXPORT_EXCEL,
} from './performance.action.type';

const performanceExportPdf = (performance: PerformanceState): PerformanceActions => {
  return {
    type: PERFORMANCE_EXPORT_PDF,
    payload: performance,
  };
};

const performanceExportExcel = (performance: PerformanceState): PerformanceActions => {
  return {
    type: PERFORMANCE_EXPORT_EXCEL,
    payload: performance,
  };
};

const performanceList = (performance: PerformanceState): PerformanceActions => {
  return {
    type: PERFORMANCE_LIST_GET,
    payload: performance,
  };
};

const performanceValidate = (performance: PerformanceState): PerformanceActions => {
  return {
    type: PERFORMANCE_VALIDATE,
    payload: performance,
  };
};

const performanceRequest = (performance: PerformanceState): PerformanceActions => {
  return {
    type: PERFORMANCE_REQUEST,
    payload: performance,
  };
};

export const exportPdf = (periodId: string, hiddenColumnNames: string[]) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceExportPdf({
        ...getState().performance,
        isFetching: true,
      })
    );
    performanceService.exportPerformancePdf(periodId, hiddenColumnNames);
    dispatch({
      type: PERFORMANCE_EXPORT_PDF,
      payload: {
        ...getState().performance,
        isFetching: false,
        message: `El archivo se esta creando. Recibirás una nueva notificación cuando este completado.`,
      },
    });
  };
};

export const exportExcel = (periodId: string, hiddenColumnNames: string[]) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceExportExcel({
        ...getState().performance,
        isFetching: true,
      })
    );
    performanceService.exportPerformanceExcel(periodId, hiddenColumnNames);
    dispatch({
      type: PERFORMANCE_EXPORT_EXCEL,
      payload: {
        ...getState().performance,
        isFetching: false,
        message: `El archivo se esta creando. Recibirás una nueva notificación cuando este completado.`,
      },
    });
  };
};

export const getPerformanceAllRevision = (
  periodId: string | null = null,
  collId: string | null = null,
  generalState: string | null = null,
  notStateRejected: string | null = null
) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceRequest({
        ...getState().performance,
        isFetching: false,
      })
    );
    const response: any = await performanceService.getPerformanceAllRevision(
      periodId,
      collId,
      generalState,
      notStateRejected
    );
    dispatch(
      performanceList({
        isFetching: false,
        status: response.status,
        message: response.message,
        performances: !periodId ? response.data : getState().performance.performances,
        selectedPerformances: periodId && collId ? response.data : undefined,
        performanceList: getState().performance.performanceList,
      })
    );
  };
};

export const getPerformanceById = (id: string) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceRequest({
        ...getState().performance,
        isFetching: true,
        performanceById: null,
      })
    );
    const response: any = await performanceService.getPerformanceById(id);
    dispatch(
      performanceList({
        ...getState().performance,
        isFetching: false,
        status: response.status,
        message: response.message,
        performanceById: response.data,
      })
    );
  };
};

export const requestEditPerformance = (id: string) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceRequest({
        ...getState().performance,
        isFetching: true,
      })
    );
    const response: any = await performanceService.getPerformanceById(id);
    dispatch(
      performanceList({
        ...getState().performance,
        isFetching: false,
        status: response.status,
        message: response.message,
        editPerformance: response.data,
      })
    );
  };
};

export const getPerformancePendingRevision = (
  periodId: string | null = null,
  collId: string | null = null
) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceRequest({
        ...getState().performance,
        isFetching: false,
      })
    );
    const response: any = await performanceService.getPerformancePendingRevision(periodId, collId);
    dispatch(
      performanceList({
        isFetching: false,
        status: response.status,
        message: response.message,
        performances: !periodId ? response.data : getState().performance.performances,
        selectedPerformances: periodId && collId ? response.data : undefined,
        performanceList: getState().performance.performanceList,
      })
    );
  };
};

export const getPendingRevision = () => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    const response: any = await performanceService.getPerformancePendingRevision(null, null);
    dispatch(
      performanceValidate({
        ...getState().performance,
        performanceList: response.data,
      })
    );
  };
};

export const getPerformanceByPeriod = (periodId: string) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceRequest({
        ...getState().performance,
        isFetching: false,
      })
    );
    const response: any = await performanceService.getPerformancesByPeriod(periodId);
    dispatch(
      performanceList({
        isFetching: false,
        status: response.status,
        message: response.message,
        performances: response.data,
        performanceList: getState().performance.performanceList,
      })
    );
  };
};

export const getPerformancesByPeriodAndCollaborator = (
  periodId: string,
  collaboratorId: number
) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceRequest({
        ...getState().performance,
        isFetching: false,
      })
    );
    const response: any = await performanceService.getPerformancesByPeriodAndCollaborator(
      periodId,
      collaboratorId
    );
    dispatch(
      performanceList({
        isFetching: false,
        status: response.status,
        message: response.message,
        performances: response.data,
        performanceList: getState().performance.performanceList,
      })
    );
  };
};

export const sendPerformanceToReview = (idPeriod: string) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceRequest({
        ...getState().performance,
        isFetching: true,
      })
    );
    const response: any = await performanceService.sendPerformanceToReview(idPeriod);
    dispatch(
      performanceList({
        ...getState().performance,
        isFetching: false,
        status: response.status,
        message: response.message,
      })
    );
  };
};

export const decisionPerformance = (
  idPeriod: string,
  idCollaborator: string,
  idsPerformance: any[],
  decision: string
) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceRequest({
        ...getState().performance,
        isFetching: true,
      })
    );
    let response: any;
    if (decision === 'approve') {
      response = await performanceService.approvePerformance(
        idPeriod,
        idCollaborator,
        idsPerformance
      );
    } else if (decision === 'reject') {
      response = await performanceService.rejectPerformance(
        idPeriod,
        idCollaborator,
        idsPerformance
      );
    }
    const responseList: any = await performanceService.getPerformancePendingRevision(null, null);
    dispatch(
      performanceList({
        ...getState().performance,
        isFetching: false,
        status: response.status,
        message: response.message,
        performanceList: responseList.data,
      })
    );
  };
};

export const annulledPerformance = (id: string) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceRequest({
        ...getState().performance,
        isFetching: true,
      })
    );
    const response: any = await performanceService.annulledPerformance(id);
    const period: Period | null | undefined = getState().period.period;
    let performances = getState().performance.performances || [];
    if (!!period && !!period.id) {
      const responseList: any = await performanceService.getPerformancesByPeriod(period.id);
      performances = responseList.data;
    }
    dispatch(
      performanceList({
        isFetching: false,
        status: response.status,
        message: response.message,
        performance: response.data,
        performances: performances,
        performanceList: getState().performance.performanceList,
      })
    );
  };
};

export const deletePerformance = (id: string) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceRequest({
        ...getState().performance,
        isFetching: true,
      })
    );
    const response: any = await performanceService.deletePerformance(id);
    const period: Period | null | undefined = getState().period.period;
    let performances = getState().performance.performances || [];
    if (!!period && !!period.id) {
      const responseList: any = await performanceService.getPerformancesByPeriod(period.id);
      performances = responseList.data;
    }
    dispatch(
      performanceList({
        isFetching: false,
        status: response.status,
        message: response.message,
        performance: response.data,
        performances: performances,
        performanceList: getState().performance.performanceList,
      })
    );
  };
};

export const updatePerformance = (
  id: string,
  objective: string,
  weight: number,
  endDate: Date,
  freeField: string | null,
  experience: string | null,
  social: string | null,
  personal: string | null,
  competenceId: string | null
) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceRequest({
        ...getState().performance,
        isFetching: true,
      })
    );
    const response: any = await performanceService.updatePerformance(
      id,
      objective,
      weight,
      endDate,
      freeField,
      experience,
      social,
      personal,
      competenceId
    );
    const period: Period | null | undefined = getState().period.period;
    let performances = getState().performance.performances || [];
    if (!!period && !!period.id) {
      const responseList: any = await performanceService.getPerformancesByPeriod(period.id);
      performances = responseList.data;
    }
    dispatch(
      performanceList({
        ...getState().performance,
        isFetching: false,
        status: response.status,
        message: response.message,
        performance: response.data,
        performances: performances,
        editPerformance: null,
      })
    );
  };
};

export const createPerformance = (performance: PerformanceDto) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceRequest({
        ...getState().performance,
        isFetching: true,
      })
    );
    const response: any = await performanceService.createPerformance(performance);
    const responseList: any = await performanceService.getPerformancesByPeriod(
      performance.periodId
    );
    dispatch(
      performanceList({
        isFetching: false,
        status: response.status,
        message: response.message,
        performance: response.data,
        performances: responseList.data,
        performanceList: getState().performance.performanceList,
      })
    );
  };
};

export const updateMessage = (message: string | null) => {
  return async (dispatch: Dispatch<PerformanceActions>, getState: () => RootState) => {
    dispatch(
      performanceRequest({
        ...getState().performance,
        message: message,
      })
    );
  };
};

