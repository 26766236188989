import { FeedbackState } from './feedback.type';

export const FEEDBACK_LIST = 'FEEDBACK_LIST';
export const FEEDBACK_REQUEST = 'FEEDBACK_REQUEST';
export const FEEDBACK_SAVED = 'FEEDBACK_SAVED';
export const FEEDBACK_CURRENT = 'FEEDBACK_CURRENT';
export const FEEDBACK_DELETE = 'FEEDBACK_DELETE';
export const FEEDBACK_CONFIRM = 'FEEDBACK_CONFIRM';
export const FEEDBACK_LEADER_CONFIRM = 'FEEDBACK_LEADER_CONFIRM';
export const FEEDBACK_LEADER_SAVED = 'FEEDBACK_LEADER_SAVED';
export const FEEDBACK_HOLIDAYS = 'FEEDBACK_HOLIDAYS';
export const FEEDBACK_LEADER_EXPORT_EXCEL = 'FEEDBACK_LEADER_EXPORT_EXCEL';
export const FEEDBACK_LEADER_EXPORT_PDF = 'FEEDBACK_LEADER_EXPORT_PDF';
export const FEEDBACK_COLLABORATOR_EXPORT_PDF = 'FEEDBACK_COLLABORATOR_EXPORT_PDF';
export const FEEDBACK_COLLABORATOR_EXPORT_EXCEL = 'FEEDBACK_COLLABORATOR_EXPORT_EXCEL';

export interface FeedbackCollaboratorExportPdfAction {
  type: typeof FEEDBACK_COLLABORATOR_EXPORT_PDF;
  payload: FeedbackState;
}

export interface FeedbackCollaboratorExportExcelAction {
  type: typeof FEEDBACK_COLLABORATOR_EXPORT_EXCEL;
  payload: FeedbackState;
}
export interface FeedbackLeaderExportExcelAction {
  type: typeof FEEDBACK_LEADER_EXPORT_EXCEL;
  payload: FeedbackState;
}
export interface FeedbackLeaderExportPdfAction {
  type: typeof FEEDBACK_LEADER_EXPORT_PDF;
  payload: FeedbackState;
}
export interface FeedbackHolidaysAction {
  type: typeof FEEDBACK_HOLIDAYS;
  payload: FeedbackState;
}
export interface FeedbackLeaderConfirmAction {
  type: typeof FEEDBACK_LEADER_CONFIRM;
  payload: FeedbackState;
}

export interface FeedbackLeaderSavedAction {
  type: typeof FEEDBACK_LEADER_SAVED;
  payload: FeedbackState;
}
export interface FeedbackConfirmAction {
  type: typeof FEEDBACK_CONFIRM;
  payload: FeedbackState;
}
export interface FeedbackDeleteAction {
  type: typeof FEEDBACK_DELETE;
  payload: FeedbackState;
}

export interface FeedbackCurrentAction {
  type: typeof FEEDBACK_CURRENT;
  payload: FeedbackState;
}

export interface FeedbackSavedAction {
  type: typeof FEEDBACK_SAVED;
  payload: FeedbackState;
}

export interface FeedbackListAction {
  type: typeof FEEDBACK_LIST;
  payload: FeedbackState;
}

export interface FeedbackRequestAction {
  type: typeof FEEDBACK_REQUEST;
  payload: FeedbackState;
}

export type FeedbackActionTypes =
  | FeedbackListAction
  | FeedbackRequestAction
  | FeedbackSavedAction
  | FeedbackCurrentAction
  | FeedbackDeleteAction
  | FeedbackConfirmAction
  | FeedbackLeaderConfirmAction
  | FeedbackLeaderSavedAction
  | FeedbackHolidaysAction
  | FeedbackLeaderExportExcelAction
  | FeedbackLeaderExportPdfAction
  | FeedbackCollaboratorExportPdfAction
  | FeedbackCollaboratorExportExcelAction;

export type FeedbackActions = FeedbackActionTypes;
