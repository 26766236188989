import { NotificationState } from './notification.type';
import {
  NOTIFICATION_LIST,
  NotificationActions,
  NOTIFICATION_REQUEST,
} from './notification.action.type';

const defaultState: NotificationState = {
  notification: null,
  notifications: [],
  isFetching: false,
  status: null,
  message: null,
};

export default (state = defaultState, action: NotificationActions): NotificationState => {
  switch (action.type) {
    case NOTIFICATION_LIST: {
      return {
        ...action.payload,
      };
    }
    case NOTIFICATION_REQUEST: {
      return {
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
