import React from 'react';
import packageJson from '../../../package.alias';
import './DinoVersion.css';

export const DinoVersion = () => {
  const json: any = packageJson;
  if (!!json) {
    return <h6 className={'version'}>{`Versión ${json.version} build ${json.build}`}</h6>;
  } else {
    console.warn('The package json is not found');
    return null;
  }
};

export default DinoVersion;
