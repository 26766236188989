import { GalaxyToast } from "@dinocloud/galaxy-components-lib";

class ErrorHandler {
  async verifyResponse(response: any) {
    if (response.status >= 300 || response.statusCode >= 300) {
      let data = await response.clone().json();
      if (data.message) {
        GalaxyToast({header:data.message,  type:'error'})
      } else if (response.status === 504 || response.statusCode === 504) {
        GalaxyToast({header:'Parece que hay un problema en la red. Intentelo más tarde.',  type:'error'});
      } else {
        GalaxyToast({header:'Hubo un error desconocido. Intentelo más tarde.',  type:'error'});
      }
    }
  }
}

const errorHandler = new ErrorHandler();

export default errorHandler;
