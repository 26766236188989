import { RouterState } from './router.type';
import { RouterActions, ROUTE_URL } from './router.actions.type';

const defaultState: RouterState = {
  title: 'Urbano',
  showHeader: true,
};

export default (state = defaultState, action: RouterActions): RouterState => {
  switch (action.type) {
    case ROUTE_URL: {
      return {
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
